import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Styles from "./Purchase.module.css";
import BannerImage from "../../Assets/Images/page404-banner.png";
import {
  buyMineToken,
  getMineAsset,
  updateTransactionHash,
} from "../../service/api";
import { useNavigate } from "react-router-dom";
import ViewModalComponent from "./ViewModalComponent";
import notify from "../../utils/notify";
import AddWalletModelComponent from "./AddWalletModelComponent";
import { useDispatch, useSelector } from "react-redux";
import metamask from "../../utils/metamask";
import { setLoader } from "../../redux/actions/layout-action";

type latestValuesType = {
  unit: string;
  paymentMethod: string;
  totalAmount: string;
  tokenHolding: string;
  validateField: string;
};

export interface Mine {
  token_asset_id: number;
  mine_id: number;
  mine_name: string;
  qty: number;
  in_process_qty: number;
  available_qty: number;
  type: string;
  ico_end_date: string;
  sale_price: number;
  currency: string;
  state: string;
}

const PurchaseComponent = ({ page }: any) => {
  const [mines, setMines] = useState<Mine[]>([
    {
      token_asset_id: 0,
      mine_id: 0,
      mine_name: "",
      qty: 0,
      in_process_qty: 0,
      available_qty: 0,
      type: "",
      ico_end_date: "",
      sale_price: 0,
      currency: "$",
      state: "",
    },
  ]);
  const { userDetails } = useSelector((state: any) => state.userReducer);
  const { screenSize, loader }: { screenSize: number; loader: boolean } =
    useSelector((state: any) => state.layoutReducer);
  const [unit, setUnit] = useState("0");
  const [unitError, setUnitError] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState("fiat");
  const [paymentMethodError, setPaymentMethodError] = useState<string>("");
  const [totalAmount, setTotalAmount] = useState("0");
  const [tokenHolding, setTokenHolding] = useState("adminCustody");
  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [walletModal, setWalletModal] = useState(false);
  const [error, setError] = useState(false);
  const [buyClick, setBuyClick] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  useEffect(() => {
    if (unit !== "" && mines.length > 0) {
      const total = Number(unit) * mines[0]?.sale_price;
      const formatTotal = total.toLocaleString();
      setTotalAmount(formatTotal);
    } else {
      setTotalAmount("");
    }
  }, [unit, mines]);

  const validatePurchaseForm = (latestValues: latestValuesType) => {
    // setApiErrorMsg('');
    // toggleSubmitByCryptoBtn(latestValues);
    switch (latestValues.validateField) {
      case "unit":
        if (latestValues.unit === "") {
          setUnitError("Please, provide the Units.");
        } // TODO rest validation
        return true;
      case "paymentMethod":
        // if (!latestValues.email.length) {
        //   setEmailError('Please, provide the email.');
        // } else if (!validator.isEmail(latestValues.email)) {
        //   setEmailError('Please, enter a valid email!');
        // }
        return true;
      case "totalAmount":
        // if (!latestValues.message.length) {
        //   setMessageError('Message is mandatory.');
        // } // TODO Rest Validation
        return true;
      case "tokenHolding":
        // if (!latestValues.message.length) {
        //   setMessageError('Message is mandatory.');
        // } // TODO Rest Validation
        return true;
      default:
        return false;
    }
  };

  const handleUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;

    if (!Number.isInteger(Number(val))) {
      notify.error("Enter a positive integers");
      setUnitError("Enter a positive integers");
      return;
    } else if (Number(val) > mines[0]?.available_qty) {
      notify.error("Value must be less than available quantity");
      setUnitError("Value must be less than available quantity");
      return;
    }
    if (/^[1-9]\d{0,4}$/.test(val) || val === "") {
      setUnit(val);

      validatePurchaseForm({
        unit: val,
        paymentMethod,
        totalAmount,
        tokenHolding,
        validateField: "unit",
      });
    } else {
      notify.error("Enter a positive integers");
      setUnitError("Enter a positive integers");
      return;
    }
  };

  const handlePaymentMethodChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = e.target.value;
    setPaymentMethodError("");
    setPaymentMethod(val);
    validatePurchaseForm({
      unit,
      paymentMethod: val,
      totalAmount,
      tokenHolding,
      validateField: "paymentMethod",
    });
  };

  const handleSelectCryptocurrency = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const submitPurchase = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setBuyClick(true);
      if (userDetails.wallet_address == "") {
        setWalletModal(true);
      } else {
        const body = {
          token_asset_id: mines[0]?.token_asset_id,
          qty: Number(unit),
          payment_type: paymentMethod,
        };

        const res: any = await buyMineToken(body);
        if (res.status) {
          if (paymentMethod == "fiat") {
            if (res.status) {
              window.open(res.payment_link, "_self");
            }
          } else {
            const stripTotalAmount = totalAmount.replace(/,/g, "");
            const resp = await metamask.transferToken(
              Number(stripTotalAmount),
              res.contract_address,
              res.admin_wallet_address,
              userDetails.wallet_address
            );
            setBuyClick(false);
            if (resp?.hash) {
              const data = {
                order_id: res.order_id,
                transaction_hash: resp.hash,
                payment_type: "crypto_currency",
              };
              const response: any = await updateTransactionHash(data);
              if (response.status) {
                notify.success(response.message);
                navigate("/my-orders");
              }
            } else {
              console.log("RES", resp);
              const data = {
                order_id: res.order_id,
                transaction_hash: "",
                payment_type: "crypto_currency",
                error_type: resp == "CALL_EXCEPTION" ? "low-balance" : "",
              };
              const response: any = await updateTransactionHash(data);
            }
          }
        } else {
          notify.error(res.message);
          setBuyClick(false);
        }
      }
    } catch (err) {
      console.log("ERR", err);
    }
  };

  useEffect(() => {
    const loadMasterData = async () => {
      try {
        const result = await getMineAsset();
        dispatch(setLoader(false));

        if (result.status && result.crypto_asset.length > 0) {
          let res = [];
          if (page == "token") {
            res = result.crypto_asset.filter(
              (data: any) => data.type == "primary_sale"
            );
            setMines(res);
          } else {
            res = result.crypto_asset.filter((data: any) => data.type == "ico");
            setMines(res);
          }
          if (res.length == 0) {
            setError(true);
          }
        } else {
          // handle failure
          setError(true);
        }
      } catch (error) {
        console.error("An error occurred during Buy Token:", error);
      }
    };
    dispatch(setLoader(true));
    loadMasterData();
  }, []);

  useEffect(() => {
    if (screenSize !== 0) {
      screenSize >= 768 ? setIsMobileScreen(false) : setIsMobileScreen(true);
    }
  }, [screenSize]);
  if (loader) {
    return;
  }
  return (
    <>
      {isMobileScreen ? (
        <form onSubmit={submitPurchase} style={{ height: "100%" }}>
          <div className={Styles.mobileBannerContainer}>
            <Row>
              <Col className={Styles.col1} md={7}>
                {!error ? (
                  <>
                    <div className={Styles.headersContainer}>
                      <Row className={Styles.mobileHeader3}>
                        <Col className={Styles.mobileHeaderTest}>
                          <div className={Styles.mobileHeader4}>
                            * The price per unit of graphite is currently set at{" "}
                          </div>
                          {mines[0]?.currency} ${mines[0]?.sale_price}.
                        </Col>
                        <Col style={{ alignSelf: "center" }}>
                          <img
                            className={Styles.mobileBannerImage}
                            src={BannerImage}
                            alt="banner_image"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <span
                          className={Styles.mobileHeader2}
                          style={{ width: "fit-content" }}>
                          I would like to purchase
                        </span>
                        <span className={Styles.mobileInputHeader}>
                          <input
                            className={`${Styles.mobileInputBox}`}
                            type="number"
                            placeholder={`x`}
                            onChange={handleUnitChange}
                            value={unit}
                          />
                        </span>
                        <span
                          className={Styles.mobileHeader2}
                          style={{ width: "fit-content" }}>
                          units of{" "}
                        </span>
                        <span
                          className={Styles.mobileHeader5}
                          style={{ width: "fit-content" }}>
                          graphite.
                        </span>
                      </Row>
                      <Row className={Styles.mobileHeader3}>
                        <div className={Styles.mobileHeader4}>
                          * Units Available{" "}
                        </div>
                        {mines[0]?.available_qty} .{" "}
                        <div className={Styles.mobileHeader4}>
                          Each unit refer to
                          <div className={Styles.mobileHeader6}>1 Ton</div> of
                          Graphite
                        </div>
                      </Row>
                    </div>
                    <br />
                    <Row className={Styles.parentRowForm}>
                      <Row
                        className={`${Styles.rowForm} ${Styles.mobileRowForm}`}>
                        <div className={Styles.subHeader1}>Payment Method</div>
                        <Col
                          className={Styles.colMethod}
                          style={{ display: "flex" }}>
                          <div className={Styles.paymentMethod}>
                            <div className={Styles.mobileRadio1}>
                              <input
                                className={Styles.radioInput}
                                type="radio"
                                name="fiat"
                                value="fiat"
                                checked={paymentMethod === "fiat"}
                                onChange={handlePaymentMethodChange}
                              />
                              <label
                                htmlFor="fiat"
                                className={Styles.mobileFormLabel}>
                                Fiat
                              </label>
                            </div>
                            <div
                              className={`${Styles.radio2} ${Styles.mobileRadio2}`}>
                              <input
                                className={Styles.radioInput}
                                type="radio"
                                name="crypto_currency"
                                value="crypto_currency"
                                checked={paymentMethod === "crypto_currency"}
                                onChange={handlePaymentMethodChange}
                              />
                              <label
                                htmlFor="cryptocurrency"
                                className={Styles.mobileFormLabel}>
                                Cryptocurrency
                              </label>
                            </div>
                          </div>
                          {paymentMethod === "crypto_currency" && (
                            <div
                              className={`${Styles.select} ${Styles.mobileSelect}`}>
                              <select
                                className={Styles.option}
                                value={selectedOption}
                                onChange={handleSelectCryptocurrency}>
                                <option
                                  selected
                                  className={Styles.formLabel}
                                  style={{ height: "100px" }}
                                  value="usdt">
                                  USDT
                                </option>
                              </select>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row
                        className={`${Styles.rowForm} ${Styles.mobileRowForm}`}>
                        <div className={Styles.subHeader1}>Total Amount</div>
                        <Col>
                          <div className={Styles.totalInput}>
                            <input
                              type="text"
                              className={`${Styles.input1} ${Styles.mobileInput}`}
                              value={totalAmount}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className={Styles.rowForm}>
                        <div className={Styles.subHeader1}>Token Holding</div>
                        <Row className={Styles.totalInput}>
                          <Col sm={4} style={{ display: "flex" }}>
                            <div
                              className={`${Styles.radio4} ${Styles.mobileRadio4}`}>
                              <input
                                className={Styles.radioInput}
                                type="radio"
                                name="adminCustody"
                                value="adminCustody"
                                checked={tokenHolding === "adminCustody"}
                                readOnly
                              />
                              <label
                                htmlFor="regular"
                                className={Styles.mobileFormLabel}>
                                Admin Custody
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                      <Row>
                        <Col>
                          <div className={Styles.mobileSubmitBtn}>
                            <button
                              type="submit"
                              className={
                                parseInt(unit) < 1 || buyClick || unit === ""
                                  ? Styles.mobileSubmitGreyButton
                                  : Styles.submitButton
                              }
                              disabled={
                                parseInt(unit) < 1 || unit === "" || buyClick
                              }>
                              <div style={{ textAlign: "center" }}>
                                Buy Graphite
                              </div>
                            </button>
                          </div>
                          <div
                            onClick={() => setShowModal(true)}
                            className={`${Styles.view} ${Styles.mobileView}`}>
                            View Public Documents
                          </div>
                        </Col>
                      </Row>
                    </Row>
                  </>
                ) : (
                  <Row className={Styles.error}>
                    {page === "ico"
                      ? "There is no active ICO Token available to purchase."
                      : "There is no active Primary Token available to purchase."}
                    <br />
                    Please try again later.
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </form>
      ) : (
        <form onSubmit={submitPurchase}>
          <div className={Styles.bannerContainer}>
            <Row>
              <Col className={Styles.col1} md={7}>
                {!error ? (
                  <>
                    <div className={Styles.headersContainer}>
                      <Row className={Styles.header3}>
                        <div className={Styles.header4}>
                          * The price per unit of graphite is currently set at{" "}
                        </div>
                        {mines[0]?.currency} ${mines[0]?.sale_price}.
                      </Row>
                      <Row>
                        <span
                          className={Styles.header2}
                          style={{ width: "fit-content" }}>
                          I would like to purchase
                        </span>
                        <span
                          className={Styles.header1}
                          style={{ width: "fit-content" }}>
                          <input
                            className={`${Styles.inputBox}`}
                            type="number"
                            placeholder={`x`}
                            onChange={handleUnitChange}
                            value={unit}
                          />
                        </span>
                        <span
                          className={Styles.header2}
                          style={{ width: "fit-content" }}>
                          units of{" "}
                        </span>
                        <span
                          className={Styles.header5}
                          style={{ width: "fit-content" }}>
                          graphite.
                        </span>
                      </Row>
                      <Row className={Styles.header3}>
                        <div className={Styles.header4}>* Units Available </div>
                        {mines[0]?.available_qty} .{" "}
                        <div className={Styles.header4}>
                          Each unit refer to
                          <div className={Styles.header6}>1 Ton</div> of
                          Graphite
                        </div>
                      </Row>
                    </div>
                    <br />
                    <Row className={Styles.parentRowForm}>
                      <Row className={Styles.rowForm}>
                        <div className={Styles.subHeader1}>Payment Method</div>
                        <Row>
                          <Col
                            className={Styles.colMethod}
                            style={{ display: "flex" }}>
                            <div className={Styles.radio1}>
                              <input
                                className={Styles.radioInput}
                                type="radio"
                                name="fiat"
                                value="fiat"
                                checked={paymentMethod === "fiat"}
                                onChange={handlePaymentMethodChange}
                              />
                              <label
                                htmlFor="fiat"
                                className={Styles.formLabel}>
                                Fiat
                              </label>
                            </div>
                            <div className={Styles.radio2}>
                              <input
                                className={Styles.radioInput}
                                type="radio"
                                name="crypto_currency"
                                value="crypto_currency"
                                checked={paymentMethod === "crypto_currency"}
                                onChange={handlePaymentMethodChange}
                              />
                              <label
                                htmlFor="cryptocurrency"
                                className={Styles.formLabel}>
                                Cryptocurrency
                              </label>
                            </div>
                            {paymentMethod === "crypto_currency" && (
                              <div className={Styles.select}>
                                <select
                                  className={Styles.option}
                                  value={selectedOption}
                                  onChange={handleSelectCryptocurrency}>
                                  {/* <option
                            className={Styles.formLabel}
                            value=""
                            disabled
                            hidden>
                            - Select Cryptocurrency -
                          </option> */}
                                  <option
                                    selected
                                    className={Styles.formLabel}
                                    style={{ height: "100px" }}
                                    value="usdt">
                                    USDT
                                  </option>
                                  {/* <option className={Styles.formLabel} value="usdc">
                            USDC
                          </option> */}
                                </select>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Row>
                      <Row className={Styles.rowForm}>
                        <div className={Styles.subHeader1}>Total Amount</div>
                        <Row>
                          <Col>
                            <div className={Styles.totalInput}>
                              <input
                                type="text"
                                className={Styles.input1}
                                value={totalAmount}
                                disabled
                              />
                            </div>
                          </Col>
                        </Row>
                      </Row>
                      <Row className={Styles.rowForm}>
                        <div className={Styles.subHeader1}>Token Holding</div>
                        <Row className={Styles.totalInput}>
                          <Col sm={4} style={{ display: "flex" }}>
                            <div className={Styles.radio4}>
                              <input
                                className={Styles.radioInput}
                                type="radio"
                                name="adminCustody"
                                value="adminCustody"
                                checked={tokenHolding === "adminCustody"}
                                readOnly
                              />
                              <label
                                htmlFor="regular"
                                className={Styles.formLabel}>
                                Admin Custody
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                      <br></br>
                      <Row>
                        <Col style={{ display: "flex" }}>
                          <div>
                            <button
                              type="submit"
                              className={
                                parseInt(unit) < 1 || buyClick || unit === ""
                                  ? Styles.submitGreyButton
                                  : Styles.submitButton
                              }
                              disabled={
                                parseInt(unit) < 1 || unit === "" || buyClick
                              }>
                              Buy Graphite
                            </button>
                          </div>
                          <div
                            onClick={() => {
                              setShowModal(true);
                            }}
                            className={Styles.view}>
                            View Public Documents
                          </div>
                        </Col>
                      </Row>
                    </Row>
                  </>
                ) : (
                  <Row className={Styles.error}>
                    {page === "ico"
                      ? "There is no active ICO Token available to purchase."
                      : "There is no active Primary Token available to purchase."}
                    <br />
                    Please try again later.
                  </Row>
                )}
              </Col>
              <Col style={{ alignSelf: "center" }}>
                <img
                  className={Styles.bannerImage}
                  src={BannerImage}
                  alt="banner_image"
                />
              </Col>
            </Row>
          </div>
        </form>
      )}
      {showModal && (
        <ViewModalComponent
          setShowModal={setShowModal}
          showModal={showModal}
          mineId={mines[0]?.mine_id}
        />
      )}
      {walletModal && (
        <AddWalletModelComponent
          walletModal={walletModal}
          setWalletModal={setWalletModal}
        />
      )}
    </>
  );
};
export default PurchaseComponent;
