//@ts-nocheck
import { useDispatch, useSelector } from "react-redux";
import Styles from "./addMetamaskCard.module.css";
import { userDetailAPI, userUpdateAPI } from "../../../service/api";
import notify from "../../../utils/notify";
import metamaskService from "../../../utils/metamask";
import walletImage from "../../../Assets/Images/goldefi/metaMaskWallet.svg";
import metaMaskFox from "../../../Assets/Images/goldefi/MetaMask_Fox.svg";
import ButtonStyle from "../../Common/Button.module.css";
import { setUserDetails } from "../../../redux/actions";
import { useEffect, useState } from "react";
import { copyText } from "../../../utils/common";
// import { ConnectButton } from "@rainbow-me/rainbowkit";

const AddMetamaskCard = () => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state: any) => state.userReducer);

  const handleWalletConnect = async () => {
    try {
      const result = await metamaskService.connectWithWallet();
      if (result && !result.error) {
        const data = {
          wallet_address: result.accountAddress,
          wallet_provided_by: "user",
        };
        const res: any = await userUpdateAPI(data);
        if (res.status) {
          notify.success(res.message);
          const resp = await userDetailAPI();
          dispatch(setUserDetails(resp));
        }
      } else {
        notify.error(result?.message || "error occured");
      }
    } catch (err: any) {
      console.log("error", err);
      if (err.code == -32002) {
        notify.error(err?.message || "error occured");
      }
    }
  };
  const copy = () => {
    copyText(userDetails.wallet_address);
    notify.success("Address copied");
  };

  return (
    <>
      <div className={Styles.card2}>
        <div className="p-2">
          <img
            className={Styles.bannerImage}
            src={walletImage}
            alt="banner_image"
          />
        </div>
        <div className="grid row">
          <div className="col-12">
            <img
              className={Styles.bannerImage}
              src={metaMaskFox}
              alt="banner_image"
              width={48}
            />
          </div>
        </div>
        <div className="grid row">
          <div className={Styles.contentBox + " col-12"}>
            <div className={Styles.textHeading}>
              {userDetails.wallet_address
                ? "Wallet Connected"
                : "Add Metamask Wallet"}
            </div>
            <div
              className={`${Styles.text} ${
                userDetails.wallet_address ? Styles.labelText : ""
              }`}>
              {userDetails.wallet_address
                ? userDetails.wallet_address
                : "This are the professional details shown to users in the app."}
            </div>
            <button
              onClick={userDetails.wallet_address ? copy : handleWalletConnect}
              className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} px-4 my-4`}>
              {userDetails.wallet_address ? "Copy Address" : "Link your wallet"}
            </button>
            {/* <ConnectButton /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMetamaskCard;
