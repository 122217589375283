/* eslint-disable react-hooks/exhaustive-deps */
//@ts-nocheck
import "./redeem.css";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRedeemList } from "../../redux/actions";
import CurrencyFormat from "react-currency-format";
import GoldTokenSmall from "../../Assets//Images/goldefi/goldTokenSmall.svg";
import GoldIcon from "../../Assets//Images/goldefi/goldIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa";
import notify from "../../utils/notify";
import { getRedeemList } from "../../service/api";
import ButtonStyle from "../../Components/Common/Button.module.css";
import Pagination from "../MyOrder/Pagination";
import GetQuoteModal from "./GetQuoteModal";
import { GoldDecimal } from "../../utils/common";
import { Tooltip as ReactTooltip } from "react-tooltip";
import LoaderComponent from "../Common/LoaderComponent";
import TransactionModalComponent from "./TransactionModalComponent";
const RedeemTokenListComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [explorerData, setExplorerData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [generalData, setGeneralData] = useState({
    available_token: 0,
    available_weight: 0,
    redeemed_value: 0,
    token_redeemed: 0,
    weight_of_gold: 0,
  });
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const fetchRedeemList = async (e = 0) => {
    try {
      setLoader(true);
      const res: any = await getRedeemList(e != 0 ? e : pageNo);
      setLoader(false);

      if (res.status) {
        dispatch(setRedeemList(res.data, res.order[0]));
        setTableData(res.data);
        setGeneralData(res.order[0]);
        setTotalPages(res.total_pages);
      }
    } catch (error) {
      console.log("redeem api error", error);
    }
  };
  useEffect(() => {
    fetchRedeemList();
  }, []);
  const DesktopOrderTable = () => {
    return (
      <div className="col-md-12 mt-3">
        <div className="row">
          <div className="col-md-12 mx-3 px-3 mb-3">
            <div className="d-flex justify-content-between">
              <div className="tableHeading col px-1 fs-6 text-center">
                Order Date / Reference
              </div>
              <div className="tableHeading col px-1 fs-6 text-center">
                Gold Weight (t oz)
              </div>
              <div className="tableHeading col px-1 fs-6 text-center">
                Token Qty (GDI)
              </div>
              <div className="tableHeading col px-1 fs-6 text-center">
                Platform Fees (GDI)
              </div>
              <div className="tableHeading col px-1 fs-6 text-center">
                Delivery fee (GDI)
              </div>
              <div className="tableHeading col px-1 fs-6 text-center">
                Non-Refundable fee (GDI)
              </div>
              <div className="tableHeading col px-1 fs-6 text-center">
                Total Value (GDI)
              </div>
              <div className="tableHeading col px-1 fs-6 text-center">
                Order Status
              </div>
              <div className="tableHeading col px-1 fs-6 text-center">
                Action
              </div>
            </div>
          </div>
          {tableData && tableData.length > 0 ? (
            tableData.map((d: any, idx: number) => {
              const inputDate = new Date(d?.request_date);

              // Format the date as "Oct-24-2023"
              const formattedDate = inputDate.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              });
              return (
                <div
                  key={`order-+${idx}`}
                  className={
                    idx % 2
                      ? "col-md-12 mx-3 tableRowDark py-4 px-3 mt-1"
                      : "col-md-12 mx-3 tableRowLight py-4 px-3 mt-1"
                  }>
                  <div className="d-flex justify-content-between">
                    <div className="tableText col px-1 fs-6 text-center">
                      <div className="tableHeading">{formattedDate} /</div>
                      <div>{d.redemption_ref}</div>
                    </div>
                    <div className="tableText col px-1 fs-6 text-center">
                      {d.total_gold.toFixed(GoldDecimal)}
                    </div>
                    <div className="tableText col px-1 fs-6 text-center">
                      {d.qty}
                    </div>

                    <div className="tableText col px-1 fs-6 text-center">
                      {d.platform_fee.toFixed(2)}
                    </div>
                    <div className="tableText col px-1 fs-6 text-center">
                      {d.delivery_fee.toFixed(2)}
                    </div>
                    <div className="tableText col px-1 fs-6 text-center">
                      {d.non_refundable_fee.toFixed(2)}
                    </div>

                    <div className="tableText col px-1 fs-6 text-center">
                      {(
                        +d.qty +
                        +d.platform_fee.toFixed(2) +
                        +d.delivery_fee.toFixed(2) +
                        +d.non_refundable_fee.toFixed(2)
                      ).toFixed(2)}
                    </div>
                    <div className="tableText col px-1 fs-6 text-center">
                      {GetOrderStatus(d.status)}
                    </div>
                    <div className="tableText col px-1 fs-6 text-center">
                      {d.explorer_data.length > 0 ? (
                        <>
                          <button
                            className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeSquareGoldBorder} mb-3 me-3`}
                            onClick={() => {
                              setExplorerData({
                                ...d.explorer_data[0],
                                redeemQuantity: d.qty,
                                redeemId: d.redemption_ref,
                                nonRefundable: d.non_refundable_fee,
                                status: d.status,
                              });
                              setShowModal(true);
                            }}>
                            Details
                          </button>
                        </>
                      ) : (
                        "N/A"
                      )}

                      {!d.show_get_quote ? (
                        ""
                      ) : (
                        <button
                          onClick={() => {
                            setShow(true);
                            setSelectedData(d);
                          }}
                          className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeSquareGoldBorder}`}>
                          View Quote
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              key="order-card-1"
              className="col-md-12 mx-3 tableRowDark py-4 px-3 mt-1">
              <h3 className="text-center text-white"> No Order Present</h3>
            </div>
          )}
        </div>
      </div>
    );
  };
  //order card mobile view
  const MobileOrderCard = () => {
    return (
      <Fragment>
        {tableData && tableData.length > 0 ? (
          tableData.map((d: any, idx: number) => {
            const inputDate = new Date(d?.request_date);
            const formattedDate = inputDate.toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "2-digit",
            });
            return (
              <div
                key={`order-card-+${idx}`}
                className={
                  idx % 2
                    ? "row orderCardDark py-3 px-2 mb-4"
                    : "row orderCardLight py-3 px-2 mb-4"
                }>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start">
                    <div className="col-md-12">Order Date/</div>
                    <div className="col-md-12">Reference</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12"> {formattedDate}</div>
                    <div className="col-md-12">{d.redemption_ref}</div>
                  </div>
                </div>
                <hr className="px-auto mt-1 text-white" />

                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Gold Weight (t oz)</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">
                      {d.total_gold.toFixed(GoldDecimal)}
                    </div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Token Quantity (GDI)</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">{d.qty}</div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Platform Fee (GDI)</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">
                      {d.platform_fee.toFixed(2)}{" "}
                    </div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Delivery Fee (GDI)</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">
                      {d.delivery_fee.toFixed(2)}{" "}
                    </div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Non-Refunable Fee (GDI)</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">
                      {d.non_refundable_fee.toFixed(2)}{" "}
                    </div>
                  </div>
                </div>

                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Total Value (GDI)</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">
                      {(
                        +d.qty +
                        +d.platform_fee.toFixed(2) +
                        +d.delivery_fee.toFixed(2) +
                        +d.non_refundable_fee.toFixed(2)
                      ).toFixed(2)}
                    </div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Order Status</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">{GetOrderStatus(d.status)}</div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Action</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">
                      {d.explorer_data.length > 0 && (
                        <>
                          <button
                            className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeSquareGoldBorder} mb-3 me-2`}
                            onClick={() => {
                              setExplorerData({
                                ...d.explorer_data[0],
                                redeemQuantity: d.qty,
                                redeemId: d.redemption_ref,
                                nonRefundable: d.non_refundable_fee,
                              });
                              setShowModal(true);
                            }}>
                            Details
                          </button>
                        </>
                      )}
                      {!d.show_get_quote ? (
                        "N/A"
                      ) : (
                        <button
                          onClick={() => {
                            setShow(true);
                            setSelectedData(d);
                          }}
                          className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeSquareGoldBorder}`}>
                          View Quote
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div key="order-card-1" className="row orderCardDark py-3 px-2 mb-4">
            <h3 className="text-center text-white"> No Order Present</h3>
          </div>
        )}
      </Fragment>
    );
  };
  // copy text to clipboard
  // const copyTrancation = (data: string) => {
  //   navigator.clipboard.writeText(data);
  //   notify.success("Wallet Address Copied");
  // };
  const navigateRedeem = () => {
    navigate("/redeem-tokens");
  };

  const GetOrderStatus = (status: string) => {
    if (status === "placed") {
      return (
        <span className="px-3 py-2 badge bg-success  rounded text-uppercase">
          {status}
        </span>
      );
    }
    if (status === "draft") {
      return (
        <span
          className="px-3 py-2 badge rounded text-uppercase"
          style={{ backgroundColor: "#8e810c" }}>
          {status}
        </span>
      );
    }
    return (
      <span
        className="px-3 py-2 badge rounded text-uppercase"
        style={{ background: "#902c0a" }}>
        {status}
      </span>
    );
  };
  return (
    <>
      {loader && (
        <div className="d-flex justify-content-center">
          <LoaderComponent message={"Fetching your redeem data"} />
        </div>
      )}
      <div className="orderContainer" id="order-body">
        <div className="p-5 mx-4">
          {/* my token section */}
          <div className="row mt-4">
            <div className="col-md-9 d-flex">
              <div className="">
                <h1 className="text-white"> Redemption Requests</h1>
              </div>
              <div className=" ms-4 justify-conetnt-center">
                <button
                  type="button"
                  className=" buyBtn px-4 py-3"
                  onClick={navigateRedeem}>
                  Redeem
                </button>
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-end  align-item-center">
              <label
                className="datePickerHeading  text-white fs-5 d-none"
                htmlFor="dateInput">
                Select Date Range
              </label>
              <input
                type="date"
                id="dateInput"
                className="dateInput px-3 py-2 d-none"
              />
            </div>
          </div>
          <div className="row justify-content-between mt-5">
            {/* my tokens  */}
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <div className="row tokenContainer px-3 py-2 ">
                <div className="col-md-4 col-sm-6 tokenBorderEnd px-4  py-2  ">
                  <div className="tokenDivHeading col-md-12 fs-6 ">
                    TOKEN REDEEMED
                  </div>
                  <div className="col-md-12 mt-1 d-flex justify-content-left align-items-center  ">
                    <img
                      src={GoldTokenSmall}
                      alt="gold"
                      className="goldTokenImg "
                    />
                    <ReactTooltip
                      id="my-tooltip-1"
                      style={{
                        backgroundColor: "#00112b",
                        color: "#fff",
                        fontFamily: "Poppins",
                      }}
                    />

                    <CurrencyFormat
                      data-tooltip-id="my-tooltip-1"
                      data-tooltip-place="top-start"
                      data-tooltip-content={generalData.token_redeemed.toFixed(
                        2
                      )}
                      value={generalData.token_redeemed}
                      displayType={"text"}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={""}
                      className="text-start fs-4 ps-2 tokenDivText   text-break"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 tokenBorderEnd px-4  py-2">
                  <div className="tokenDivHeading col-md-12 fs-6 ">
                    WEIGHT OF GOLD
                  </div>
                  <div className="col-md-12 d-flex mt-1 text-start jusitify-content-center">
                    <div>
                      <img src={GoldIcon} alt="gold" className="goldTokenImg" />
                    </div>
                    <div>
                      <span className="tokenDivText pe-2 fs-7 align-bottom text-break">
                        <span className=" px-2 fs-4">
                          <ReactTooltip
                            id="my-tooltip-2"
                            style={{
                              backgroundColor: "#00112b",
                              color: "#fff",
                              fontFamily: "Poppins",
                              fontSize: 15,
                              fontStyle: "normal",
                            }}
                          />
                          <CurrencyFormat
                            data-tooltip-id="my-tooltip-2"
                            data-tooltip-place="top-start"
                            data-tooltip-content={generalData.weight_of_gold.toFixed(
                              GoldDecimal
                            )}
                            value={generalData.weight_of_gold}
                            displayType={"text"}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={""}
                            className="text-start  tokenDivText   text-break"
                          />
                        </span>
                        t oz
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12  px-4  py-2">
                  <div className="tokenDivHeading col-md-12 fs-6 ">
                    REDEEMED VALUE
                  </div>
                  <div className="col-md-12 mt-1 text-start">
                    <ReactTooltip
                      id="my-tooltip-3"
                      style={{
                        backgroundColor: "#00112b",
                        color: "#fff",
                        fontFamily: "Poppins",
                      }}
                    />
                    <CurrencyFormat
                      data-tooltip-id="my-tooltip-3"
                      data-tooltip-place="top-start"
                      data-tooltip-content={generalData.redeemed_value.toFixed(
                        2
                      )}
                      value={generalData.redeemed_value}
                      displayType={"text"}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={"$ "}
                      className="text-start fs-4 pe-2 tokenDivText   text-break"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/*token available  */}
            <div className="col-lg-4 col-md-12 col-sm-12 mt-sm-3">
              <div className="row">
                <div className="col-md-6  col-sm-6 tokenBorderEnd pe-1  py-2">
                  <div className="tokenDivHeading col-md-12 fs-6">
                    AVAILABLE TOKENS
                  </div>
                  <div className="d-flex align-items-center mt-1 ">
                    <div>
                      <img
                        src={GoldTokenSmall}
                        alt="gold"
                        className="goldTokenImg"
                      />
                    </div>
                    <div>
                      <CurrencyFormat
                        value={generalData.available_token}
                        displayType={"text"}
                        decimalScale={2}
                        thousandSeparator={true}
                        prefix={""}
                        className="tokenDivText ms-1 px-1 fs-4 text-break "
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 ps-3 pe-1  py-2">
                  <div className="tokenDivHeading col-md-12 fs-6 ">
                    WEIGHT OF Gold
                  </div>
                  <div className="col-md-12 d-flex mt-1 text-start jusitify-content-center">
                    <div>
                      <img src={GoldIcon} alt="gold" className="goldTokenImg" />
                    </div>
                    <div>
                      <span className="tokenDivText fs-7 align-bottom text-break">
                        <span className=" px-2 fs-4">
                          <ReactTooltip
                            id="my-tooltip-4"
                            style={{
                              backgroundColor: "#00112b",
                              color: "#fff",
                              fontFamily: "Poppins",
                              fontSize: 15,
                              fontStyle: "normal",
                            }}
                          />
                          <CurrencyFormat
                            data-tooltip-id="my-tooltip-4"
                            data-tooltip-place="top-start"
                            data-tooltip-content={generalData.available_weight.toFixed(
                              GoldDecimal
                            )}
                            value={generalData.available_weight}
                            displayType={"text"}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={""}
                            className="text-start  tokenDivText   text-break"
                          />
                        </span>
                        t oz
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ordeer section */}
          <div className="col-md-12 mt-3">
            <div className="row">
              <div>
                <h2 className="text-bold text-white text-start">
                  Orders Placed
                </h2>
              </div>
            </div>
          </div>
          {/* desktop view */}
          <div className="d-none d-md-block d-xl-block mb-4">
            <DesktopOrderTable />
          </div>
          {/* desktop view */}
          <div className="d-block d-xl-none	 d-md-none mb-4">
            <MobileOrderCard />
          </div>
          <div className="d-flex justify-content-center">
            {tableData && tableData.length > 0 ? (
              <Pagination
                callAPI={fetchRedeemList}
                totalPages={totalPages}
                setPageNo={setPageNo}
                pageNo={pageNo}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <GetQuoteModal
        show={show}
        onHide={() => setShow(false)}
        data={selectedData}
        fetchData={fetchRedeemList}
      />
      <TransactionModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        data={explorerData}
      />
    </>
  );
};
export default RedeemTokenListComponent;
