import Styles from "./buyToken.module.css";
import infoIcon from "../../Assets/Images/goldefi/infoIcon.svg";
import icon1 from "../../Assets/Images/goldefi/icon1.svg";
import icon2 from "../../Assets/Images/goldefi/icon2.svg";
import icon3 from "../../Assets/Images/goldefi/icon3.svg";
import icon4 from "../../Assets/Images/goldefi/Icon4.svg";
import icon5 from "../../Assets/Images/goldefi/icon5.svg";
import icon7 from "../../Assets/Images/goldefi/Icon7.svg";
import CirclePercentage from "../../Assets/Images/goldefi/circle-percentage.svg";
import InputField from "../Common/InputField";
import { useEffect, useState } from "react";
import SelectComponent from "./SelectComponent";
import ButtonStyles from "../../Components/Common/Button.module.css";
import RedirectPage from "./RedirectPage";
import { buyMineToken, updateTransactionHash } from "../../service/api";
import notify from "../../utils/notify";
import metamask from "../../utils/metamask";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../Common/LoaderComponent";
import { getMineAsset } from "../../service/api";
import { setLoader } from "../../redux/actions/layout-action";
import { useSelector, useDispatch } from "react-redux";
import BankTransferModalComponent from "./BankTransferModal";
import ThresholdModalComponent from "./ThresholdModalComponent";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { IoSearch } from "react-icons/io5";
import { GoldDecimal } from "../../utils/common";

export interface Range {
  from: number;
  to: number;
  perc: number;
}

export interface Ranges {
  [key: string]: Range;
}
const BuyToken = () => {
  const [tokenDetails, setTokenDetails] = useState({
    live_gold_rate: 0,
    platform_fee: 0,
    thresold_value: 0,
    is_crypto_currency: [],
    selectedCrypto: "",
    selectAmount: 0,
    time: 0,
    annual_thresold_limit: 0,
    minimum_fee: 0,
    total_invested_price: 0,
    fixed: 0,
    range: {},
    perc_fee: 0,
    active: "",
    minimum_token: 0,
  });
  const [paymentMode, setPaymentMode] = useState("crypto_currency");
  const [isSuccess, setIsSuccess] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderId: "",
    order_ref: "",
  });
  const [successfulTransaction, setSuccessfulTransaction] = useState();
  const [usdtValue, setUsdtValue] = useState(0);
  const [seconds, setSeconds] = useState(60);
  const [amount, setAmount] = useState(0);
  const [tokenCount, setTokenCount] = useState(0);
  const [redirectScreen, setRedirectScreen] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { userDetails } = useSelector((state: any) => state.userReducer);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [thresholdModal, setThresholdModal] = useState(false);
  const mgToGram = 0.001;
  const gramToTroyOz = 1 / 31.1035;
  const buy = async () => {
    if (paymentMode === "fiat") setShowLoader(true);
    else {
      if (
        paymentMode === "crypto_currency" &&
        tokenDetails.selectedCrypto === ""
      ) {
        notify.error("Please select crypto currency");
        return;
      }
      setIsSuccess(false); // pending
      if (paymentMode !== "bank_transfer" && paymentMode !== "cash") {
        setRedirectScreen(paymentMode);
      }
    }

    try {
      // setBuyClick(true);

      if (
        userDetails.wallet_address == "" &&
        userDetails.temp_wallet_address === ""
      ) {
        // setWalletModal(true);
        notify.error("Connect Metamask Wallet");
      } else {
        const body = {
          // token_asset_id: mines[0]?.token_asset_id,
          qty: tokenCount,
          payment_type: paymentMode,
        };
        const res: any = await buyMineToken(body);
        if (res.status) {
          if (paymentMode === "fiat") {
            if (res.status) {
              setShowLoader(false);
              window.open(res.payment_link, "_self");
            } else {
              notify.error(res.message);
              setShowLoader(false);
            }
          } else if (paymentMode === "cash") {
            if (res.status) {
              setIsSuccess(true);
              notify.success(res.message);
              navigate("/my-orders");
            } else {
              setIsSuccess(false);
              notify.error(res.message);
            }
          } else if (paymentMode === "bank_transfer") {
            setOrderDetails({
              orderId: res.order_id,
              order_ref: res.order_ref,
            });
            setAmount(res.total_amount_to_be_paid);
            setShowModal(true);
          } else {
            setOrderDetails({
              orderId: res.order_id,
              order_ref: res.order_ref,
            });
            const resp = await metamask.transferToken(
              tokenCount,
              res.contract_address,
              res.admin_wallet_address,
              ""
              // userDetails.wallet_address
            );
            if (resp?.hash) {
              const data = {
                order_id: res.order_id,
                transaction_hash: resp.hash,
                payment_type: "crypto_currency",
              };
              const response: any = await updateTransactionHash(data);
              if (response.status) {
                setIsSuccess(true);
                setSuccessfulTransaction(response);
                notify.success(response.message);
                // navigate("/my-orders");
              } else {
                setRedirectScreen("");
                setIsSuccess(false);
                notify.error(response.message);
              }
            } else {
              const data = {
                order_id: res.order_id,
                transaction_hash: "",
                payment_type: "crypto_currency",
                error_type: resp == "CALL_EXCEPTION" ? "low-balance" : "",
              };
              const response: any = await updateTransactionHash(data);
              if (response.status) {
                setIsSuccess(true);
                setSuccessfulTransaction(response);

                notify.success(response.message);
                // navigate("/my-orders");
              } else {
                setRedirectScreen("");
                setIsSuccess(false);
                // notify.error(response.message);
              }
            }
          }
        } else {
          notify.error(res.message);
          setShowLoader(false);
          setRedirectScreen("");
          setIsSuccess(false);
        }
      }
    } catch (err) {
      console.log("ERR", err);
    }
  };

  useEffect(() => {
    if (tokenCount && tokenDetails.selectedCrypto) {
      const fetchPrice = async () => {
        try {
          const options = {
            method: "GET",
            headers: {
              accept: "application/json",
              "x-cg-demo-api-key": "CG-AxEotVU43var3416XgmhVYqf",
            },
          };
          const response = await fetch(
            "https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd",
            options
          );
          const data = await response.json();
          setUsdtValue(data.tether.usd);
        } catch (err) {
          console.error("Error fetching USDT rate:", err);
        }
      };

      fetchPrice();

      const timer = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            fetchPrice();
            return 60;
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [tokenCount && tokenDetails.selectedCrypto]);
  const calculateUSD = () => {
    const addFees = calculateFee();
    const number = tokenCount ? tokenCount : 1;
    const grams = number * mgToGram;
    // const grams = number;
    const usd = grams * tokenDetails.live_gold_rate;

    if (
      tokenDetails.active == "Percentage" ||
      tokenDetails.active == "By Range"
    ) {
      if (addFees === tokenDetails.minimum_fee) {
        return usd + addFees;
      } else {
        return usd + (addFees / 100) * usd;
      }
    } else {
      return usd + addFees;
    }
  };
  const calculateTroyOz = () => {
    const number = tokenCount ? tokenCount : 1;
    const grams = number * mgToGram;
    // const grams = number;
    return grams * gramToTroyOz;
  };
  const calculateUSDT = () => {
    if (usdtValue) {
      const usdValue = calculateUSD();
      //@ts-ignore
      return usdValue / usdtValue;
    }
  };
  const updateToken = (newValue: string) => {
    setTokenDetails((prev) => ({
      ...prev,
      selectedCrypto: newValue,
    }));
  };
  const calculatePercentage = (count: number, ranges: Ranges): number => {
    let calculatedPercentage = 0;
    let matched = false;
    const rangesArray = Object.values(ranges);

    rangesArray.forEach((range, index) => {
      if (count >= range.from && count <= range.to) {
        calculatedPercentage = range.perc;
        matched = true;
      }
      if (!matched && index === rangesArray.length - 1) {
        calculatedPercentage = range.perc;
      }
    });

    return calculatedPercentage;
  };
  const calculateFee = () => {
    const fees =
      tokenDetails.active == "Percentage"
        ? tokenDetails.perc_fee
        : tokenDetails.active == "Fixed"
        ? tokenDetails.fixed
        : tokenDetails.active == "By Range"
        ? calculatePercentage(tokenCount, tokenDetails.range)
        : 0;
    return fees;
    // if (tokenDetails.active == "Fixed") {
    //   return fees;
    // } else {
    //   const number = tokenCount || 1;
    //   const grams = number * mgToGram;
    //   const usd = grams * tokenDetails.live_gold_rate;
    //   return (fees / 100) * usd > tokenDetails.minimum_fee
    //     ? fees
    //     : tokenDetails.minimum_fee;
    // }
  };

  useEffect(() => {
    const loadMasterData = async () => {
      try {
        const result: any = await getMineAsset();
        dispatch(setLoader(false));
        if (result.status && result.crypto_asset) {
          const res = result.crypto_asset;

          setTokenDetails((prev) => ({
            ...prev,
            live_gold_rate: res.live_gold_rate || 0,
            platform_fee: res.platform_fee || 0,
            thresold_value: res.thresold_value || 0,
            is_crypto_currency: res.is_crypto_currency.map((data: string) => {
              return { label: data, value: data };
            }),
            fixed: res.fixed || 0,
            perc_fee: res.perc_fee || 0,
            minimum_fee: res.minimum_fee || 0,
            annual_thresold_limit: res.annual_thresold_limit || 0,
            total_invested_price: res.total_invested_price || 0,
            range: res.range_percentage || {},
            active: res.active,
            minimum_token: res.minimum_token || 0,
          }));
        } else {
          // handle failure
          setError(true);
        }
      } catch (error) {
        console.error("An error occurred during Buy Token:", error);
      }
    };
    dispatch(setLoader(true));
    loadMasterData();
  }, []);

  return (
    <div className={Styles.buyTokenContainer}>
      {redirectScreen === "" && (
        <>
          <div
            className={
              Styles.buyToken + " " + (showLoader ? Styles.opaque : "")
            }>
            <div className={Styles.buyTokenText}>Buy Tokens</div>
            <div className={Styles.totalCard}>
              <div className={Styles.totalCol1}>
                Total Available Gold
                <div className={Styles.unitCount}>
                  50
                  <span className={Styles.unit}>
                    oz <img src={infoIcon} alt="info" />
                  </span>
                </div>
              </div>
              <div className={Styles.totalCol2}>
                Total Token
                <div className={Styles.unitCount}>1,417,000</div>
              </div>
            </div>
          </div>

          <div className={"d-grid gap-5 " + (showLoader ? Styles.opaque : "")}>
            <div className="d-grid gap-4 gap-md-5">
              <div
                className={
                  Styles.textContainer +
                  " " +
                  (paymentMode !== "" ? Styles.checked : "")
                }>
                {paymentMode !== "" && (
                  <img className={Styles.tickImg} src={icon7} alt="" />
                )}
                <div>
                  <div className={Styles.headText}>Let’s get started</div>
                  <div className={Styles.subText}>
                    How would you like to purchase your GDI Tokens?
                  </div>
                </div>
              </div>
              <div className={Styles.btnContainer}>
                <button
                  className={
                    Styles.cardbtn +
                    " " +
                    (paymentMode === "crypto_currency" ? "" : Styles.shadow)
                  }
                  onClick={() => setPaymentMode("crypto_currency")}>
                  <div
                    className={
                      (Styles.mobileCard ?? "") +
                      " grid row gap-sm-0 gap-md-3 w-100 m-0"
                    }>
                    <div className={"col-2 p-0 " + Styles.icon1}>
                      <img src={icon1} alt="buy" />
                    </div>
                    <div className={"col-8 p-0 " + Styles.text1}>
                      Buy using <br />
                      <span className={Styles.text2}>Crypto-currencies</span>
                    </div>
                  </div>
                </button>
                <button
                  className={
                    Styles.cardbtn +
                    " " +
                    (paymentMode === "fiat" ? "" : Styles.shadow)
                  }
                  onClick={() => setPaymentMode("fiat")}>
                  <div
                    className={
                      (Styles.mobileCard ?? "") +
                      " grid row gap-sm-0 gap-md-3 w-100 m-0"
                    }>
                    <div className={"col-2 p-0 " + Styles.icon1}>
                      <img src={icon2} alt="buy" />
                    </div>
                    <div className={"col-8 p-0 " + Styles.text1}>
                      Buy using <br />
                      <span className={Styles.text2}>Online Payment</span>
                    </div>
                  </div>
                </button>
                <button
                  className={
                    Styles.cardbtn +
                    " " +
                    (paymentMode === "cash" ? "" : Styles.shadow)
                  }
                  onClick={() => setPaymentMode("cash")}>
                  <div
                    className={
                      (Styles.mobileCard ?? "") +
                      " grid row gap-sm-0 gap-md-3 w-100 m-0"
                    }>
                    <div className={"col-2 p-0 " + Styles.icon1}>
                      <img src={icon3} alt="buy" />
                    </div>
                    <div className={"col-8 p-0 " + Styles.text1}>
                      Buy using <br />
                      <span className={Styles.text2}>Cash</span>
                    </div>
                  </div>
                </button>
                <button
                  className={
                    Styles.cardbtn +
                    " " +
                    (paymentMode === "bank_transfer" ? "" : Styles.shadow)
                  }
                  onClick={() => setPaymentMode("bank_transfer")}>
                  <div
                    className={
                      (Styles.mobileCard ?? "") +
                      " grid row gap-sm-0 gap-md-3 w-100 m-0"
                    }>
                    <div className={"col-2 p-0 " + Styles.icon1}>
                      <img src={icon3} alt="buy" />
                    </div>
                    <div className={"col-8 p-0 " + Styles.text1}>
                      Buy using <br />
                      <span className={Styles.text2}>Bank Transfer</span>
                    </div>
                  </div>
                </button>
              </div>
              <div className={Styles.btnContainer}>
                {paymentMode === "cash" && (
                  <div className={Styles.search}>
                    <a
                      className={`${Styles.searchButton} ${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground}`}
                      target="_blank"
                      href={"/search-distributor"}>
                      <IoSearch />
                      Search the nearest Distributer
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                "d-grid gap-4 gap-md-5 " +
                (paymentMode === "" ? Styles.shadow : "")
              }>
              <div
                className={
                  Styles.textContainer +
                  " " +
                  (tokenCount > 0 ? Styles.checked : "")
                }>
                {tokenCount > 0 && (
                  <img className={Styles.tickImg} src={icon7} alt="" />
                )}
                <div>
                  <div className={Styles.headText}>Next</div>
                  <div className={Styles.subText}>
                    Enter number of GDI Tokens to purchase
                  </div>
                </div>
              </div>
              <div
                className={
                  Styles.btnContainer + " " + Styles.mobilebtnContainer
                }>
                <div className={Styles.inputToken}>
                  <InputField
                    value={String(tokenCount)}
                    type="number"
                    placeholder="token"
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      if (/^\d*$/.test(inputValue)) {
                        const intValue = parseInt(inputValue, 10);
                        if (
                          (isNaN(intValue) || intValue >= -1) &&
                          JSON.stringify(intValue).length < 10
                        ) {
                          setTokenCount(intValue);
                        }
                      }
                    }}
                    name="token"
                    maxLength={10}
                    dark={true}
                    showIcon={true}
                    disabled={paymentMode === ""}
                  />
                </div>
                <div className={Styles.inputToken}>
                  <div className={Styles.percentageContainer}>
                    {tokenDetails.active === "Fixed" ? (
                      <HiOutlineCurrencyDollar size={40} color="grey" />
                    ) : (
                      <img src={CirclePercentage} alt="Percentage" />
                    )}
                    <div className={Styles.percentageNumber}>
                      {calculateFee()}{" "}
                      <span className={Styles.percentageText}>
                        Platform Fees
                      </span>
                    </div>
                  </div>
                </div>
                <div className={Styles.inputText}>
                  {tokenCount || 1} Token ={" "}
                  {calculateTroyOz().toFixed(GoldDecimal)} (Troy oz of gold) =
                  {tokenCount < tokenDetails.minimum_token
                    ? `Please purchase tokens equal or greater than ${tokenDetails.minimum_token}`
                    : ` $${calculateUSD().toFixed(2)} USD`}
                </div>
              </div>
            </div>
            {paymentMode !== "cash" &&
              paymentMode !== "bank_transfer" &&
              paymentMode !== "fiat" && (
                <div
                  className={
                    "d-grid gap-4 gap-md-5 " +
                    (tokenCount > 0 ? "" : Styles.shadow)
                  }>
                  <div className={Styles.textContainer}>
                    <div className={Styles.headText}>Now</div>
                    <div className={Styles.subText}>
                      {paymentMode === "crypto_currency"
                        ? "Kindly choose the cryptocurrency from the dropdown menu and confirm the amount that will be deducted from your Metamask wallet."
                        : paymentMode === "fiat"
                        ? "Kindly choose the Online Paymnent from the dropdown menu and confirm the amount that will be charged from you."
                        : "Kindly choose the purchase method first."}
                    </div>
                  </div>
                  <div className={`${Styles.btnContainer}`}>
                    <div className={Styles.currencySelect}>
                      <div
                        className={
                          Styles.mobileSelect + " d-flex gap-2 gap-md-4"
                        }>
                        <SelectComponent
                          value={tokenDetails.selectedCrypto}
                          disabled={tokenCount <= 0}
                          onChange={(e: string) => {
                            updateToken(e);
                          }}
                          list={tokenDetails.is_crypto_currency}
                          showImg={false}
                          dark={true}
                          disableAutoSelect={true}
                        />
                        <div className={`${Styles.inputContainer}`}>
                          {/* <div>
                            <img
                              src={icon4}
                              className={Styles.iconImg}
                              alt="token"
                            />
                          </div> */}
                          <div className={Styles.inputFieldContainer}>
                            <div className={Styles.priceCrypto}>
                              {tokenCount && tokenDetails.selectedCrypto
                                ? calculateUSDT()?.toFixed(2)
                                : "Price in Crypto"}
                            </div>
                          </div>
                        </div>
                        {tokenCount && tokenDetails.selectedCrypto && (
                          <div className={Styles.textCrypto}>
                            <div className={Styles.textCrypto}>
                              <div className={Styles.countdownContainer}>
                                <div className={Styles.buyTokentextCrypto}>
                                  Next fetch in: {tokenCount ? seconds : "N/A"}{" "}
                                  seconds
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={"d-flex gap-4 " + Styles.reverseText}>
                        <div
                          className={`d-flex gap-1 ${Styles.textCrypto} ${Styles.infoText}`}>
                          <div>
                            <img width={"24px"} src={icon5} alt="info" />
                          </div>
                          <div className="">
                            {paymentMode === "crypto_currency"
                              ? "Currently, Tether is the sole option available. If you don't have a Tether account, kindly utilize an alternative purchasing method located at the top to acquire GDI tokens."
                              : paymentMode === "fiat"
                              ? "At present, Euro is the only available option. If you prefer not to transact with Euro, please use an alternative purchasing method located at the top to obtain GDI tokens."
                              : ""}
                          </div>
                        </div>
                        <div
                          className={`${Styles.textCrypto} ${Styles.unitsText}`}>
                          1 USD = 1 Tether
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <div
              className={
                "d-grid gap-5 " + (paymentMode === "" ? Styles.shadow : "")
              }>
              <div className={Styles.loginBtn}>
                <button
                  type="submit"
                  className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}
                  onClick={() => {
                    if (tokenCount < tokenDetails.minimum_token) {
                      notify.error(
                        `Please purchase tokens equal or greater than ${tokenDetails.minimum_token}`
                      );
                      return;
                    }
                    if (
                      tokenDetails.thresold_value <
                        parseFloat(calculateUSD().toFixed(2)) ||
                      tokenDetails.annual_thresold_limit <
                        parseFloat(calculateUSD().toFixed(2)) ||
                      tokenDetails.total_invested_price >
                        tokenDetails.annual_thresold_limit
                    ) {
                      setThresholdModal(true);
                    } else {
                      buy();
                    }
                  }}
                  disabled={tokenCount < 1}>
                  <span>Buy</span>
                </button>

                {(paymentMode === "cash" ||
                  paymentMode === "bank_transfer" ||
                  paymentMode === "fiat") && (
                  <div
                    className={`d-flex gap-1 mt-4 ${Styles.textCrypto} ${Styles.infoText}`}>
                    <div>
                      <img width={"24px"} src={icon5} alt="info" />
                    </div>
                    <div className="">
                      Please be aware that after placing your order, you'll need
                      to visit the nearest distributor to finalize your
                      purchase. This involves physically verifying your KYC and
                      completing the selected transaction.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {redirectScreen !== "" && (
        <RedirectPage
          mode={paymentMode}
          isSuccess={isSuccess}
          orderDetails={orderDetails}
          successfulTransaction={successfulTransaction}
        />
      )}
      {showModal && (
        <BankTransferModalComponent
          showModal={showModal}
          setShowModal={setShowModal}
          tokenCount={amount}
          orderId={orderDetails.orderId}
          orderRef={orderDetails.order_ref}
          setTokenCount={setTokenCount}
        />
      )}

      <ThresholdModalComponent
        show={thresholdModal}
        onHide={() => setThresholdModal(false)}
        onConfirm={() => {
          setThresholdModal(false);
          buy();
        }}
      />

      {showLoader && (
        <LoaderComponent
          message={"You are being directed to a third-party payment gateway"}
        />
      )}
    </div>
  );
};
export default BuyToken;
