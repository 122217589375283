import {
  _isMetamaskInstalled,
  _connectToMetamask,
  _disconnectToMetamask,
  _getActiveAccount,
  _tranferToken,
  _generateWalletAddress,
  _createRedemption,
  _processRedeemRequest,
  _signMessage,
  _createRedemptionSign,
  _directTranferToken,
  // _genosisSignTransaction,
} from "../service/metamask_service";
import { MetamaskSign } from "./interfaces/inputInterface";

// check is metamask wallet installed or not
const checkMetamaskInstallation = async () => {
  const result = await _isMetamaskInstalled();
  return result;
};
const connectWithWallet = async () => {
  const result = await _connectToMetamask();
  return result;
};
const disconnectWithWallet = async () => {
  const result = await _disconnectToMetamask();
  return result;
};
const getUserAddress = async () => {
  const address = await _getActiveAccount();
  return address;
};
const transferToken = async (
  data: number,
  contractAddress: string,
  adminWallet: string,
  userWallet: string
) => {
  const hash = await _tranferToken(
    data,
    contractAddress,
    adminWallet,
    userWallet
  );
  return hash;
};
const directTransferToken = async (
  data: number,
  contractAddress: string,
  adminWallet: string,
  userWallet: string
) => {
  console.log("check", data, contractAddress, adminWallet, userWallet);
  const hash = await _directTranferToken(
    data,
    contractAddress,
    adminWallet,
    userWallet
  );
  return hash;
};
// Deprecated Function
const createRedeemToken = async (
  contractAddress: string,
  redemption_id: string,
  amount: number,
  processing_fee: number
) => {
  const hash = await _createRedemption(
    contractAddress,
    redemption_id,
    amount,
    processing_fee
  );
  return hash;
};
// Deprecated Function
const processRedeemRequest = async (
  contractAddress: string,
  redemption_id: string
) => {
  const hash = await _processRedeemRequest(contractAddress, redemption_id);
  return hash;
};
const signMessage = async (message: string) => {
  const hash = await _signMessage(message);
  return hash;
};

const generateAddress = (username: string) => {
  const hash = _generateWalletAddress(username);
  return hash;
};
// const genosisSignTransaction = async () => {
//   const hash = await _genosisSignTransaction();
//   return hash;
// };
const createRedeemptionSignature = async (
  message: MetamaskSign,

  wallet_address: any
) => {
  const hash = await _createRedemptionSign(message, wallet_address);
  return hash;
};
export default {
  checkMetamaskInstallation,
  connectWithWallet,
  disconnectWithWallet,
  getUserAddress,
  transferToken,
  generateAddress,
  // genosisSignTransaction,
  createRedeemToken,
  processRedeemRequest,
  signMessage,
  createRedeemptionSignature,
  directTransferToken,
};
