import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Styles from "./OrderDetailsComponent.module.css";
import ButtonStyle from "../../Common/Button.module.css";
import InputField from "../../Common/InputField";
import { submitPayment } from "../../../service/api";
import { _userSign } from "../../../service/metamask_service";
import { stripLow } from "validator";
import CurrencyFormat from "react-currency-format";

interface Props {
  handleSubmit: (value: boolean) => void;
  orders: any;
}

const OrderDetailsComponent = ({ orders, handleSubmit }: Props) => {
  const GetOrderStatus = (status: string) => {
    if (status === "done") {
      return (
        <span className="px-3 py-2 badge bg-success  rounded text-uppercase">
          {status}
        </span>
      );
    }
    if (status === "draft") {
      return (
        <span
          className="px-3 py-2 badge rounded text-uppercase"
          style={{ backgroundColor: "#8e810c" }}>
          {status}
        </span>
      );
    }
    return (
      <span
        className="px-3 py-2 badge rounded text-uppercase"
        style={{ background: "#902c0a" }}>
        {status}
      </span>
    );
  };

  return (
    <div className={Styles.mainDiv}>
      <div className="container">
        <div className={Styles.row + " row"}>
          <div className={Styles.col + " col-xs-12 col-sm-6 col-md-4 col-lg-4"}>
            <div className="grid-item">
              <h6 className={Styles.text1}>Order ID</h6>
              <h6 className={Styles.text2}>{orders.order_ref}</h6>
            </div>
          </div>
          <div className={Styles.col + " col-xs-12 col-sm-6 col-md-4 col-lg-4"}>
            <div className="grid-item">
              <h6 className={Styles.text1}>Order Date</h6>
              <h6 className={Styles.text2}>{orders.order_date}</h6>
            </div>
          </div>
          <div className={Styles.col + " col-xs-12 col-sm-6 col-md-4 col-lg-4"}>
            <div className="grid-item">
              <h6 className={Styles.text1}>Token quantity</h6>
              <h6 className={Styles.text2}>{orders.qty}</h6>
            </div>
          </div>
          <div className={Styles.col + " col-xs-12 col-sm-6 col-md-4 col-lg-4"}>
            <div className="grid-item">
              <h6 className={Styles.text1}>Order Status</h6>
              <h6 className={Styles.text2}>{GetOrderStatus(orders.status)}</h6>
            </div>
          </div>
          <div className={Styles.col + " col-xs-12 col-sm-6 col-md-4 col-lg-4"}>
            <div className="grid-item">
              <h6 className={Styles.text1}>Amount Paid</h6>
              <h6 className={Styles.text2}>
                <CurrencyFormat
                  value={orders.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </h6>
            </div>
          </div>
          <div className={Styles.col + " col-xs-12 col-sm-6 col-md-4 col-lg-4"}>
            <div className="grid-item">
              <h6 className={Styles.text1}>Payment Mode</h6>
              <h6 className={Styles.text2}>{orders.payment}</h6>
            </div>
          </div>
          <div className={Styles.col + " col-xs-12 col-sm-6 col-md-4 col-lg-4"}>
            <div className="grid-item">
              <h6 className={Styles.text1}>Consumer</h6>
              <h6 className={Styles.text2}>{orders.consumer}</h6>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={() => handleSubmit(orders)}
        className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} ${Styles.btn}`}>
        Update Payment Details
      </button>
    </div>
  );
};

export default OrderDetailsComponent;
