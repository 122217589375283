// // import AWS from "aws-sdk";
// // import { KMSClient, SignCommand } from "@aws-sdk/client-kms";
// // AWS.config.update({
// //   accessKeyId: "AKIAVRUVQ4HVS5FGVTP6",
// //   secretAccessKey: "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",
// //   region: "us-east-1",
// // });

// // const kms = new AWS.KMS();

// // const generateDataKey = async () => {
// //   try {
// //     const params = {
// //       KeyId: "43e899d7-0662-4f09-929f-5d97c93b8640",
// //       KeySpec: "RSA_2048", // Use an appropriate KeySpec for your needs
// //     };
// //     const { Plaintext, CiphertextBlob } = await kms
// //       .generateDataKey(params)
// //       .promise();
// //     return { Plaintext, CiphertextBlob };
// //   } catch (error) {
// //     console.error("Error generating data key:", error);
// //     return null;
// //   }
// // };
// // const signData = async (data, dataKey) => {
// //   try {
// //     const { Plaintext } = dataKey;
// //     const signParams = {
// //       KeyId: "43e899d7-0662-4f09-929f-5d97c93b8640",
// //       Plaintext,
// //       Message: data,
// //     };
// //     const { Signature } = await kms.sign(signParams).promise();
// //     return Signature;
// //   } catch (error) {
// //     console.error("Error signing data:", error);
// //     return null;
// //   }
// // };
// // export { signData, generateDataKey };

import {
  KMSClient,
  SignCommand,
  GetPublicKeyCommand,
} from "@aws-sdk/client-kms";
import CryptoJS from "crypto-js";
import { Buffer } from "buffer";
const config = {
  region: "eu-north-1", // The AWS region where your KMS keys are located
  credentials: {
    accessKeyId: "AKIAZI2LGC2R7U6AKAWN", // Your AWS access key ID
    secretAccessKey: "BYOeU16R95WtJXpf+DkSAR7FM/PaBd63pLxUWBku", // Your AWS secret access key
  },
};
const client = new KMSClient(config);
const createHash = (info) => {
  const hashedData = CryptoJS.SHA256(info).toString(CryptoJS.enc.Hex);

  const hashBuffer = Buffer.from(hashedData, "hex");
  return hashBuffer;
};
const SignMessage = async (info) => {
  const hash = createHash(info);
  const input = {
    KeyId: "43e899d7-0662-4f09-929f-5d97c93b8640",
    Message: hash,
    MessageType: "DIGEST",
    SigningAlgorithm: "ECDSA_SHA_256",
  };
  const command = new SignCommand(input);
  const response = await client.send(command);
  return response;
};
const GetPublicKey = async () => {
  const input = {
    KeyId: "43e899d7-0662-4f09-929f-5d97c93b8640",
    GrantTokens: ["STRING_VALUE"],
  };
  const command = new GetPublicKeyCommand(input);
  const response = await client.send(command);
  return response;
};
export { SignMessage, GetPublicKey };
