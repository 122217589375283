import React from "react";
import Styles from "./InputField.module.css";
import { InputInterface } from "../../../utils/interfaces/inputInterface";
import goldefiIcon from "../../../Assets/Images/goldefi/Goldefi_icons.svg";
import Blind from "../../../Assets/Images/goldefi/blind.svg";

const InputField = ({
  label,
  important = false,
  value,
  onChange,
  showPassword,
  handleTogglePassword,
  type,
  placeholder,
  disabled,
  height,
  name,
  maxLength,
  showInfo,
  dark = false,
  showIcon = false,
  editable = false,
  iconPath,
  autoComplete = true,
  dateValidation = true,
  min = "",
}: InputInterface) => {
  let currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - 18);
  const minDate = currentDate.toISOString().split("T")[0];
  return (
    <div
      className={`${value ? Styles.highlight : ""} ${
        editable ? Styles.inputContainerDisable : ""
      } ${dark ? Styles.dark : ""} ${Styles.inputContainer}`}>
      {showIcon && (
        <div>
          <img
            src={iconPath ? iconPath : goldefiIcon}
            className={Styles.iconImg}
            alt="token"
          />
        </div>
      )}
      <div
        className={
          Styles.inputFieldContainer +
          " " +
          (type === "password" ? Styles.disFlex : "")
        }>
        {label && (value || type === "date") && (
          <div className={Styles.labelContainer}>
            <p className={Styles.label}>
              {label} {important && <b style={{ color: "#FF0003" }}>*</b>}
              {/* {showInfo && (
                <>
                  <Tooltip id="my-tooltip" style={{ width: "400px" }} />
                  <img
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={showInfo}
                    src={Info}
                    alt="info"
                    className={Styles.editImg}
                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                  />
                </>
              )} */}
            </p>
          </div>
        )}

        <input
          className={`${dark ? Styles.inputBoxdark : ""} ${Styles.inputBox}`}
          type={showPassword ? "text" : type}
          placeholder={`${placeholder || label}`}
          onChange={(e) => {
            onChange(e);
          }}
          value={value}
          disabled={disabled}
          style={{ height }}
          name={name}
          maxLength={maxLength}
          min={!dateValidation ? min : ""}
          max={
            type === "date" && dateValidation
              ? minDate
              : new Date().toISOString().split("T")[0]
          }
          autoComplete={autoComplete ? "new-password" : ""}
        />
        {type === "password" && (
          <button
            type="button"
            className={Styles.toggleButton}
            onClick={handleTogglePassword}
            disabled={disabled}>
            <img src={showPassword ? Blind : Blind} alt="hide" />
          </button>
        )}
      </div>
    </div>
  );
};
export default InputField;
