// @ts-nocheck
import { useState, useEffect } from "react";
import Styles from "./searchOrders.module.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ButtonStyle from "../Common/Button.module.css";
import { adminDetailsAPI, distributorDetail } from "../../service/api";

import SearchIcon from "../../Assets/Images/goldefi/searchIcon.svg";
import SearchIcon2 from "../../Assets/Images/goldefi/searchLoading.svg";
import LoaderComponent from "../Common/LoaderComponent";
import notify from "../../utils/notify";
import { CountrySelectField, StateSelectField } from "../Common/SelectField";
import { getShortName, removeBlackListCountry } from "../../utils/common";

const SearchOrdersComponent = () => {
  const [address, setAddress] = useState({
    countryName: "",
    stateName: "",
  });
  const [showLoader, setShowLoader] = useState(false);
  const [distributorList, setDistributorList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const getCountryList = async () => {
    const res: any = await adminDetailsAPI();
    if (res.status) {
      const data = res.admin_details.countries_details;
      const blackList = res.black_listed_countries;

      setCountryList(removeBlackListCountry(data, blackList));
    } else {
      setCountryList([]);
    }
  };
  const getDistributerList = async () => {
    if (
      address.countryName !== "" &&
      address.stateName !== "" &&
      address.stateName !== "Select State" &&
      address.stateName !== "Select Country"
    ) {
      const body = {
        country: address.countryName,
        state: address.stateName,
        sort_by: "name",
      };
      const res: any = await distributorDetail(body);
      if (res.status) {
        setDistributorList(res.data);
        notify.success(res.message);
      } else {
        notify.error(res.message);
        setDistributorList([]);
      }
    } else {
      setDistributorList([]);
      if (
        address.countryName === "" ||
        address.stateName === "Select Country"
      ) {
        notify.error("Please Select a Country.");
        return;
      }
      if (address.stateName === "" || address.stateName === "Select State") {
        notify.error("Please Select a State.");
        return;
      }
    }
  };
  useEffect(() => {
    getCountryList();
  }, []);
  const changeStates = (country: string) => {
    const sta = countryList.find((countryObj) => {
      return countryObj?.country_name === country;
    });
    setStateList(sta.states);
  };
  const DesktopListTable = () => {
    return (
      <div className="row mt-4 w-100 overflow-hidden ">
        <div className="col-md-12 mx-3 px-3 mb-3">
          <div
            className={`d-flex justify-content-between ${Styles.searchDistributorTable}`}>
            <div className="tableHeading col px-1 fs-6">Name</div>
            <div className="tableHeading col px-1 fs-6">Phone</div>
            <div className="tableHeading col px-1 fs-6">Email</div>
            <div className="tableHeading col px-1 fs-6">Street</div>
            <div className="tableHeading col px-1 fs-6">City</div>
            <div className="tableHeading col px-1 fs-6">State</div>
            <div className="tableHeading col  fs-6">Country</div>
          </div>
        </div>
        {distributorList &&
          distributorList.length > 0 &&
          distributorList.map((d: any, idx: number) => {
            return (
              <div
                key={`order-+${idx}`}
                className={
                  idx % 2
                    ? "col-md-12 mx-3 tableRowDark py-4 px-3 mt-1"
                    : "col-md-12 mx-3 tableRowLight py-4 px-3 mt-1"
                }>
                <div className="d-flex justify-content-between">
                  <div className=" tableText col px-1 fs-6">
                    {d.name || "N/A"}
                  </div>
                  <div className="tableText col px-1 fs-6">
                    {d.phone || "N/A"}
                  </div>
                  <div className="tableText col px-1 fs-6">
                    <ReactTooltip
                      id="my-tooltip-1"
                      style={{
                        backgroundColor: "#00112b",
                        color: "#fff",
                        fontFamily: "Poppins",
                      }}
                    />
                    <span
                      data-tooltip-id="my-tooltip-1"
                      data-tooltip-place="top-start"
                      data-tooltip-content={d.email}>
                      {getShortName(d.email, false) || "N/A"}
                    </span>
                  </div>
                  <div className="tableText col px-1 fs-6">
                    {d.street + " " + d.street || "N/A"}
                  </div>
                  <div className="tableText col px-1 fs-6">
                    {d.city || "N/A"}
                  </div>
                  <div className="tableText col px-1 fs-6">
                    {d.state || "N/A"}
                  </div>
                  <div className="tableText col px-1 fs-6">
                    {d.country || "N/A"}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };
  const MobileOrderCard = () => {
    return (
      <>
        {distributorList && distributorList.length > 0 ? (
          distributorList.map((d: any, idx: number) => {
            return (
              <div
                key={`order-card-+${idx}`}
                className={
                  idx % 2
                    ? "row orderCardDark py-3 px-2 mb-4"
                    : "row orderCardLight py-3 px-2 mb-4"
                }>
                <div className="tableHeading col px-1 fs-6"></div>

                <div className="row mt-2 py-2">
                  {/* <hr className="px-auto mt-1 text-white" /> */}
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">Name</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.name || "N/A"}</div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">Phone</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.phone || "N/A"}</div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">Email</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.email || "N/A"} </div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">Street</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">
                        {d.street + " " + d.street || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">City</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.city || "N/A"}</div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">State</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.state || "N/A"}</div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">State</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.state || "N/A"}</div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">Country</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.countey || "N/A"}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div key="order-card-1" className="row orderCardDark py-3 px-2 mb-4">
            <h3 className="text-center text-white"> No Order Present</h3>
          </div>
        )}
      </>
    );
  };
  return (
    <div className={Styles.mainDiv}>
      <div className={Styles.searchBar}>
        <div className={Styles.srchtxt}>
          <img className={Styles.srchImg} src={SearchIcon} alt="Search" />
          <h6 className={Styles.txt}> Search for Distributer near you</h6>
        </div>
        <div className={Styles.inputToken}>
          <CountrySelectField
            value={address.countryName}
            type="text"
            options={countryList}
            placeholder="Select Country"
            name="Country"
            dark={true}
            onChange={(e) => {
              setAddress({
                ...address,
                countryName: e.target.value,
              });
              changeStates(e.target.value);
            }}
          />
        </div>
        <div className={Styles.inputToken}>
          <StateSelectField
            value={address.stateName}
            options={stateList}
            type="text"
            placeholder="Select State"
            name="State"
            dark={true}
            onChange={(e) => {
              setAddress({
                ...address,
                stateName: e.target.value,
              });
            }}
          />
        </div>
        <div className={Styles.searchButton}>
          <button
            onClick={getDistributerList}
            className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} ${Styles.btn} me-3 px-2`}>
            Search
          </button>
        </div>
      </div>

      {distributorList.length === 0 ? (
        <div className={Styles.search}>
          <img className={Styles.srchImg2} src={SearchIcon2} alt="Search" />
        </div>
      ) : (
        <>
          <div className="d-none d-md-block d-xl-block mb-4">
            <DesktopListTable />
          </div>
          <div className="d-block d-xl-none	 d-md-none mb-4">
            <MobileOrderCard />
          </div>
        </>
      )}

      {showLoader && (
        <LoaderComponent
          message={"We are looking for your Distributer near you!"}
        />
      )}
    </div>
  );
};
export default SearchOrdersComponent;
