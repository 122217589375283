import React, { useState } from "react";
import Styles from "./PasswordInputField.module.css";
import { InputInterface } from "../../../utils/interfaces/inputInterface";
import Info from "../../../Assets/Images/info.svg";
import Blind from "../../../Assets/Images/goldefi/blind.svg";
import PasswordValidation from "./PasswordValidation";
import { FaRegEye } from "react-icons/fa6";
const PasswordInputField = ({
  label,
  important = false,
  value,
  onChange,
  showPassword,
  handleTogglePassword,
  type,
  placeholder,
  disabled,
  height,
  name,
  maxLength,
  showInfo,
  dark = false,
  showValid = false,
  autoComplete = true,
}: any) => {
  const [show, setShow] = useState(false);
  const [validPassword, setValidPassword] = useState({
    valid1: false,
    valid2: false,
    valid3: false,
    valid4: false,
    valid5: false,
  });

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const lengthRegex = /.{8,}/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /\d/;
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (lengthRegex.test(value.trim())) {
      setValidPassword((prev) => {
        return {
          ...prev,
          valid1: true,
        };
      });
    } else {
      setValidPassword((prev) => {
        return {
          ...prev,
          valid1: false,
        };
      });
    }

    if (uppercaseRegex.test(value.trim())) {
      setValidPassword((prev) => {
        return {
          ...prev,
          valid2: true,
        };
      });
    } else {
      setValidPassword((prev) => {
        return {
          ...prev,
          valid2: false,
        };
      });
    }

    if (lowercaseRegex.test(value.trim())) {
      setValidPassword((prev) => {
        return {
          ...prev,
          valid3: true,
        };
      });
    } else {
      setValidPassword((prev) => {
        return {
          ...prev,
          valid3: false,
        };
      });
    }

    if (numberRegex.test(value.trim())) {
      setValidPassword((prev) => {
        return {
          ...prev,
          valid4: true,
        };
      });
    } else {
      setValidPassword((prev) => {
        return {
          ...prev,
          valid4: false,
        };
      });
    }

    if (specialCharacterRegex.test(value.trim())) {
      setValidPassword((prev) => {
        return {
          ...prev,
          valid5: true,
        };
      });
    } else {
      setValidPassword((prev) => {
        return {
          ...prev,
          valid5: false,
        };
      });
    }
  };

  return (
    <>
      <div
        className={`${value ? Styles.highlight : ""} ${
          dark ? Styles.dark : ""
        } ${Styles.inputContainer}`}>
        <div className={Styles.inputFieldContainer}>
          <div className="w-100">
            {label && value && (
              <div className={Styles.labelContainer}>
                <p className={Styles.label}>
                  {label} {important && <b style={{ color: "#FF0003" }}>*</b>}
                  {/* {showInfo && (
                    <>
                      <Tooltip id="my-tooltip" style={{ width: "400px" }} />
                      <img
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={showInfo}
                        src={Info}
                        alt="info"
                        className={Styles.editImg}
                        style={{ paddingLeft: "5px", paddingRight: "5px" }}
                      />
                    </>
                  )} */}
                </p>
              </div>
            )}
            <input
              className={`${dark ? Styles.inputBoxdark : ""} ${
                Styles.inputBox
              }`}
              type={showPassword ? "text" : type}
              placeholder={`${placeholder || label}`}
              onChange={(e) => {
                if (showValid) changeHandler(e);
                onChange(e);
              }}
              value={value}
              disabled={disabled}
              style={{ height }}
              autoComplete={autoComplete ? "new-password" : ""}
              name={name}
              maxLength={maxLength}
              onFocus={() => setShow(true)}
              onBlur={() => setShow(false)}
            />
          </div>
          {type === "password" && (
            <button
              type="button"
              className={Styles.toggleButton}
              onClick={handleTogglePassword}
              disabled={disabled}>
              {showPassword ? (
                <img src={Blind} alt="hide" />
              ) : (
                <FaRegEye color="#dbdfdf" size="20" />
              )}

              {/* {showPassword ? "Hide" : "Show"} */}
            </button>
          )}
        </div>
      </div>
      <PasswordValidation
        show={show && showValid}
        validPassword={validPassword}
      />
    </>
  );
};
export default PasswordInputField;
