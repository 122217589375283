import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { userDetailAPI, userUpdateAPI } from "../../../service/api";
import Styles from "./AddWalletModelComponent.module.css";
import ButtonStyle from "../../Common/Button.module.css";
import URLS from "../../../utils/api-end-points";
import { Link, useNavigate } from "react-router-dom";
import metamask from "../../../utils/metamask";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../../redux/actions";
import notify from "../../../utils/notify";
interface Props {
  walletModal: boolean;
  setWalletModal: (value: boolean) => void;
}
const AddWalletModelComponent = ({ walletModal, setWalletModal }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state: any) => state.userReducer);
  const updateWalletAddress = async (walletAddress: string) => {
    const data = {
      temp_wallet_address: walletAddress,
      wallet_provided_by: "script",
    };
    const res: any = await userUpdateAPI(data);
    if (res.status) {
      notify.success(res.message);
      const resp = await userDetailAPI();
      dispatch(setUserDetails(resp));
      setWalletModal(false);
    }
  };
  const onNav = () => {
    navigate("/my-profile");
  };
  const handleGenerateWallet = () => {
    const hash = metamask.generateAddress(userDetails.name);
    updateWalletAddress(hash);
  };

  return (
    <>
      <Modal
        size="lg"
        centered
        show={walletModal}
        onHide={() => setWalletModal(false)}>
        <Modal.Body className="p-5">
          <div className={Styles.header}>Add Wallet Address</div>
          <div className={Styles.body}>
            To buy the Token please update wallet address in{" "}
            <Link to="/my-profile">My Profile</Link> otherwise a temporary
            address will be generated to store the tokens. You can claim the
            tokens any time from this wallet.
          </div>
          <button
            onClick={handleGenerateWallet}
            className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} me-3 px-2`}>
            Generate Wallet
          </button>
          <button
            onClick={onNav}
            className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} px-2`}>
            Add wallet
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddWalletModelComponent;
