import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import metamaskService from "../metamask";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsMetamaskInstalled,
  setUserDetails,
  setUserLoginStatus,
} from "../../redux/actions";
import { getAccessToken, getKYCStatus, getTokenExpire } from "../common";
import Loader from "../../Components/Common/Loader";
import { userDetailAPI, userGetKYCAPI } from "../../service/api";
import { setKycReason, setKycStatus } from "../../redux/actions/user-action";
import { setLoader } from "../../redux/actions/layout-action";
import LoaderComponent from "../../Components/Common/LoaderComponent";
const PrivateRoute = () => {
  const dispatch = useDispatch();
  const [isAuthenticate, setIsAuthenticate] = useState(true);
  const { isMetamaskInstalled, userDetails } = useSelector(
    (state: any) => state.userReducer
  );
  const currentDate = new Date();
  const token_expire = new Date(
    userDetails.token_expire ? userDetails.token_expire : getTokenExpire()
  );
  const { loader } = useSelector((state: any) => state.layoutReducer);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoader(true));
      try {
        const accessToken = getAccessToken();
        if (accessToken) {
          // if (token_expire.getTime() > currentDate.getTime()) {
          dispatch(setUserLoginStatus(true));
          setIsAuthenticate(true);
          dispatch(setLoader(false));
          if (
            Object.keys(userDetails).length === 0 ||
            userDetails.message == "Login Successfully!"
          ) {
            const res = await userDetailAPI();
            dispatch(setUserDetails(res));
          }
          if (
            !userDetails.kycStatus ||
            userDetails.kycStatus == null ||
            userDetails.kycStatus == undefined
          ) {
            const response: any = await userGetKYCAPI();
            dispatch(setKycStatus(getKYCStatus(response.kyc_status)));
            dispatch(setKycReason(response.reason ? response.reason : ""));
          }
          // } else {
          //   setIsAuthenticate(false);
          //   dispatch(setUserLoginStatus(false));
          //   dispatch(setLoader(false));
          // }
        } else {
          setIsAuthenticate(false);
          dispatch(setUserLoginStatus(false));
          dispatch(setLoader(false));
        }
      } catch (error) {
        console.log("privateRoute : ", error);
      }
    };
    const fetchMetamaskInstallation = async () => {
      try {
        const result = await metamaskService.checkMetamaskInstallation();
        if (result) {
          dispatch(setIsMetamaskInstalled(result));
          //   fetchUserAddress();
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    !isMetamaskInstalled && fetchMetamaskInstallation();
  }, []);

  return isAuthenticate ? (
    <>
      {loader && <LoaderComponent message="" />}
      <div className={loader ? "bg-grey" : ""}>
        <Outlet />
      </div>
    </>
  ) : (
    <Navigate to="/login" />
  );
};
export default PrivateRoute;
