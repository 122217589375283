// @ts-nocheck

import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Styles from "./MyProfile.module.css";
import ProfileDetailCard from "./ProfileDetailCard";
import AddMetamaskCard from "./AddMetamaskCard";
import LoaderComponent from "../Common/LoaderComponent";

const MyProfileComponent = ({ role }) => {
  const [loader, setLoader] = useState(false);
  return (
    <>
      {loader && (
        <div className="d-flex justify-content-center">
          <LoaderComponent message={"OTP Sending to the new email added"} />
        </div>
      )}
      <div className={Styles.page}>
        <div className={Styles.MyProfile}>My Profile</div>

        <div className="grid row mt-5 gap-4 gap-md-4 gap-lg-0">
          <div
            className={ "col-12 col-sm-8 col-md-12 col-lg-8"}>
            <ProfileDetailCard role={role} setLoader={setLoader} />
          </div>
          {role === "user" && (
            <div className="col-12 col-lg-4 col-md-12 col-sm-12">
              <AddMetamaskCard />
            </div>
          )}
          {role === "distributor" && (
            <div className="col-12 col-lg-4 col-md-12 col-sm-12">
              <AddMetamaskCard />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default MyProfileComponent;
