/* eslint-disable react-hooks/exhaustive-deps */
import "./viewPayments.css";
import { Fragment, useEffect, useState } from "react";
import { getPaymentList } from "../../service/api";
import { useDispatch, useSelector } from "react-redux";
import { setOrderList, setPaymentList } from "../../redux/actions";
import Pagination from "./Pagination";
import CurrencyFormat from "react-currency-format";
import GoldTokenSmall from "../../Assets//Images/goldefi/goldTokenSmall.svg";
import { FaRegCopy } from "react-icons/fa";
import notify from "../../utils/notify";
import { getDateFormat } from "../../utils/common";

const ViewPaymentsComponent = () => {
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [generalData, setGeneralData] = useState({
    total_payment_received: 0,
    total_tokens: 0,
  });

  const { paymentList } = useSelector((state: any) => state.paymentReducer);

  const getPaymentReceipts = async () => {
    try {
      const res: any = await getPaymentList(pageNo);
      if (res.status) {
        dispatch(setPaymentList(res.data));
        setGeneralData({
          total_payment_received: res.payment_recieved,
          total_tokens: res.token_assigned,
        });
        setDataLoaded(true);
        setTotalPages(res.total_pages);
      }
    } catch (error) {
      console.log("eerr", error);
    }
  };

  useEffect(() => {
    getPaymentReceipts();
  }, []);
  const tdata = [
    {
      date: "12-01-2024",
      qty: 10,
      reference: "#123",
      status: "draft",
      value: 130,
      weight: 14,
      paymentMethod: "crypto",
      transaction: "aede43fdfsfdfssfddfxf",
    },
    {
      date: "12-02-2024",
      qty: 10,
      reference: "#123",
      status: "placed",
      value: 130,
      weight: 14,
      paymentMethod: "crypto",
      transaction: "aede43fdfsfdfssfddfxf",
    },
    {
      date: "12-03-2024",
      qty: 10,
      reference: "#123",
      status: "cancel",
      value: 130,
      weight: 14,
      paymentMethod: "crypto",
      transaction: "aede43fdfsfdfssfddfxf",
    },
    {
      date: "12-04-2024",
      qty: 10,
      reference: "#123",
      status: "placed",
      value: 130,
      weight: 14,
      paymentMethod: "crypto",
      transaction: "aede43fdfsfdfssfddfxf",
    },
    {
      date: "12-04-2024",
      qty: 10,
      reference: "#123",
      status: "draft",
      value: 130,
      weight: 14,
      paymentMethod: "crypto",
      transaction: "aede43fdfsfdfssfddfxf",
    },
    {
      date: "12-05-2024",
      qty: 10,
      reference: "#123",
      status: "placed",
      value: 130,
      weight: 14,
      paymentMethod: "crypto",
      transaction: "aede43fdfsfdfssfddfxf",
    },
  ];

  const DesktopOrderTable = () => {
    return (
      <Fragment>
        <div className="col-md-12 mt-3">
          <div className="row">
            <div className="col-md-12 mx-3 px-3 mb-3">
              <div className="d-flex justify-content-between">
                <div className="tableHeading col px-1 fs-6">
                  Order Date/Reference
                </div>
                <div className="tableHeading col px-1 fs-6">Consumer</div>
                <div className="tableHeading col px-1 fs-6">Token Quantity</div>
                <div className="tableHeading col px-1 fs-6">Amount Paid</div>
                <div className="tableHeading col px-1 fs-6">Order Status</div>
                <div className="tableHeading col px-1 fs-6">Payment Method</div>
                <div className="tableHeading col px-1 fs-6">Payment Date</div>
              </div>
            </div>
            {paymentList && paymentList.length > 0 ? (
              paymentList.map((d: any, idx: number) => {
                return (
                  <div
                    key={`order-+${idx}`}
                    className={
                      idx % 2
                        ? "col-md-12 mx-3 tableRowDark py-4 px-3 mt-1"
                        : "col-md-12 mx-3 tableRowLight py-4 px-3 mt-1"
                    }>
                    <div className="d-flex justify-content-between">
                      <div className="d-grid tableText col px-1 fs-6">
                        <div className="tableHeading">
                          {new Date(d.time_stamp).toLocaleDateString()}
                        </div>
                        <div>#{d.order_ref}</div>
                      </div>
                      <div className="tableText col px-1 fs-6">
                        {d.consumer}
                      </div>
                      <div className="tableText col px-1 fs-6">{d.qty}</div>
                      <div className="tableText col px-1 fs-6">{d.amount}</div>
                      <div className="tableText col px-1 fs-6">
                        {GetOrderStatus(d.state)}
                      </div>
                      <div className="tableText col px-1 fs-6 text-uppercase">
                        {d.payment_type}
                      </div>
                      <div className="tableText col px-1 fs-6">
                        {getDateFormat(d.time_stamp)}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                key="order-card-1"
                className="col-md-12 mx-3 tableRowDark py-4 px-3 mt-1">
                <h3 className="text-center text-white"> No Order Present</h3>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  };
  //order card mobile view
  const MobileOrderCard = () => {
    return (
      <Fragment>
        {paymentList && paymentList.length > 0 ? (
          paymentList.map((d: any, idx: number) => {
            return (
              <div
                key={`order-card-+${idx}`}
                className={
                  idx % 2
                    ? "row orderCardDark py-3 px-2 mb-4"
                    : "row orderCardLight py-3 px-2 mb-4"
                }>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start">
                    <div className="col-md-12">Order Date/</div>
                    <div className="col-md-12">Reference</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">
                      {" "}
                      {new Date(d.time_stamp).toLocaleDateString()}
                    </div>
                    <div className="col-md-12">{d.order_ref}</div>
                  </div>
                </div>
                <hr className="px-auto mt-1 text-white" />
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Consumer</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">{d.consumer}</div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Token Quantity</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">{d.qty}</div>
                  </div>
                </div>

                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Amount Paid</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">{d.amount}</div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Order Status</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">{GetOrderStatus(d.state)}</div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Payment Method</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">{d.payment_type}</div>
                  </div>
                </div>
                {/* <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Transaction Link</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">
                      {d.transaction.substring(0, 12) + "... "}
                      <FaRegCopy
                        fontSize={14}
                        onClick={() => {
                          copyTrancation(d.transaction);
                        }}
                        className="ms-1"
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            );
          })
        ) : (
          <div key="order-card-1" className="row orderCardDark py-3 px-2 mb-4">
            <h3 className="text-center text-white"> No Order Present</h3>
          </div>
        )}
      </Fragment>
    );
  };
  // copy text to clipboard
  const copyTrancation = (data: string) => {
    navigator.clipboard.writeText(data);
    notify.success("Transaction link copied");
  };

  const GetOrderStatus = (status: string) => {
    if (status === "done") {
      return (
        <span className="px-3 py-2 badge bg-success  rounded text-uppercase">
          {status}
        </span>
      );
    }
    if (status === "draft") {
      return (
        <span
          className="px-3 py-2 badge rounded text-uppercase"
          style={{ backgroundColor: "#8e810c" }}>
          {status}
        </span>
      );
    }
    return (
      <span
        className="px-3 py-2 badge rounded text-uppercase"
        style={{ background: "#902c0a" }}>
        {status}
      </span>
    );
  };
  return (
    <>
      <div className="orderContainer" id="order-body">
        <div className="p-2 p-md-5 mx-4">
          {/* my token section */}
          <div className="row mt-4">
            <div className="col-md-9 d-flex">
              <div className="">
                <h1 className="text-white">Payment Receipts</h1>
              </div>
              <div className=" ms-4 justify-conetnt-center"></div>
            </div>
            <div className="col-md-3 d-none justify-content-end  align-item-center">
              <label
                className="datePickerHeading text-white fs-5"
                htmlFor="dateInput">
                Select Date Range
              </label>
              <input type="date" id="dateInput" className="dateInput" />
            </div>
          </div>
          <div className="row mt-5">
            {/* my tokens  */}
            <div className="col-md-4 col-sm-12 ">
              <div className="row tokenContainer px-3 py-2 ">
                <div className="col-6 tokenBorderEnd px-md-4 py-2">
                  <div className="tokenDivHeading col-md-12 fs-6 ">
                    TOKEN ASSIGNED
                  </div>
                  <div className="col-md-12 mt-1 d-flex justify-content-left align-items-center  ">
                    <img
                      src={GoldTokenSmall}
                      alt="gold"
                      className="goldTokenImg "
                    />
                    <CurrencyFormat
                      value={generalData.total_tokens}
                      displayType={"text"}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={""}
                      className="text-start fs-4 ps-2 tokenDivText   text-break"
                    />
                  </div>
                </div>
                <div className="col-6 px-md-4 py-2">
                  <div className="tokenDivHeading col-md-12 fs-6 ">
                    PAYMENT RECEIVED
                  </div>
                  <div className="col-md-12 mt-1 text-start">
                    <CurrencyFormat
                      value={generalData.total_payment_received}
                      displayType={"text"}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={"$ "}
                      className="text-start fs-4 pe-2 tokenDivText   text-break"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ordeer section */}
          <div className="col-md-12 mt-3 mb-3 mb-md-0">
            <div className="row">
              <div>
                <h2 className="text-bold text-white text-start">
                  Orders Received
                </h2>
              </div>
            </div>
          </div>
          {/* desktop view */}
          <div className="d-none d-md-block d-xl-block mb-4">
            <DesktopOrderTable />
          </div>
          {/* desktop view */}
          <div className="d-block d-xl-none	 d-md-none mb-4">
            <MobileOrderCard />
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewPaymentsComponent;
