/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import "./secondarySale.css";
import { setOrderBook, setSecondaryTradeHistory } from "../../redux/actions";
import {
  buySecondaryToken,
  getOrderBookDetails,
  getSecondaryTradeHistory,
  updateTransactionHash,
} from "../../service/api";
import ArrowUp from "../../Assets/Images/upArrow.png";
import ArrowDown from "../../Assets/Images/downArrow.png";
import AddWalletModelComponent from "../PurchaseComponent/AddWalletModelComponent";
import metamask from "../../utils/metamask";
import { useNavigate } from "react-router-dom";
import notify from "../../utils/notify";

const SecondarySaleBody = () => {
  const [qty, setQty] = useState(0);
  const [availableQty, setAvailableQty] = useState(0);
  const [cost, setCost] = useState(0);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [walletModal, setWalletModal] = useState(false);
  const [buyBtn, setBuyBtn] = useState(false);
  const navigate = useNavigate();

  const { userDetails } = useSelector((state: any) => state.userReducer);
  const { orderBook, tradeHistory } = useSelector(
    (state: any) => state.secondarySaleReducer
  );

  const dispatch = useDispatch();

  const getOrderBookData = async () => {
    try {
      const res: any = await getOrderBookDetails();
      dispatch(setOrderBook(res.data));
      const bookData: [] = res.data;
      const total = bookData.reduce((n, { volume }) => n + volume, 0);
      setAvailableQty(total);
    } catch (error) {
      console.log("eerr", error);
    }
  };

  const getTradeHistory = async () => {
    try {
      const res: any = await getSecondaryTradeHistory();

      dispatch(setSecondaryTradeHistory(res.data));
    } catch (error) {
      console.log("eerr", error);
    }
  };
  useEffect(() => {
    getOrderBookData();
    getTradeHistory();
  }, []);
  const handleQtyChange = (val: number) => {
    if (val > availableQty) {
      setError(true);
      setErrorMsg("Cannot buy more than available token.");
      return;
    }
    setError(false);
    setErrorMsg("");
    let tempCost = 0;
    let qtyNeeded = val;
    setQty(val);
    for (let i = 0; i < orderBook.length; i += 1) {
      let vol = orderBook[i].volume;
      if (vol > 0) {
        if (qtyNeeded > vol) {
          qtyNeeded -= vol;
          tempCost += vol * orderBook[i].price;
        } else {
          tempCost += qtyNeeded * orderBook[i].price;
          qtyNeeded = 0;
        }
        if (qtyNeeded === 0) {
          break;
        }
      }
    }
    setCost(tempCost);
  };
  const handlePurchase = async () => {
    if (userDetails.wallet_address === "") {
      setWalletModal(true);
      return;
    }
    const data = {
      qty,
      payment_type: "crypto_currency",
      custody_type: "own",
    };
    try {
      const res: any = await buySecondaryToken(data);
      if (res.status) {
        const stripTotalAmount = cost;
        const resp = await metamask.transferToken(
          Number(stripTotalAmount),
          res.contract_address,
          res.admin_wallet_address,
          ""
        );
        setBuyBtn(true);
        if (resp?.hash) {
          const data = {
            order_id: res.order_id,
            transaction_hash: resp.hash,
            payment_type: "crypto_currency",
          };
          const response: any = await updateTransactionHash(data);
          if (response.status) {
            notify.success(response.message);
            navigate("/my-orders");
          }
        } else {
          const data = {
            order_id: res.order_id,
            transaction_hash: "",
            payment_type: "crypto_currency",
            error_type: resp === "CALL_EXCEPTION" ? "low-balance" : "",
          };

          await updateTransactionHash(data);
        }
      } else {
        notify.error(res.message);
        setBuyBtn(true);
      }
    } catch (error) {
      console.log("eerr", error);
    }
  };
  return (
    <Fragment>
      <div className="orderpage px-2 mt-5">
        <Row className="order-row px-5 py-3 ">
          <Col className="order-column" xs={12} sm={12} md={12} lg={12}>
            <div className="place-order pb-4">
              <div className="heading">
                <h1 className="order-title">PLACE ORDER</h1>
              </div>

              <div className="mb-3 order-form">
                <p className="sub-heading">ENTER QUANTITY</p>
                <input
                  type="number"
                  className="form-control"
                  id="quantity"
                  name="quantity"
                  min={0}
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  onChange={(e) => handleQtyChange(Number(e.target.value))}
                />
              </div>
              {error && (
                <span className="text-center text-danger">{errorMsg}</span>
              )}
              <div className="order-form">
                <p className="sub-heading">TOTAL PRICE</p>
                <input
                  type="text"
                  className="form-control"
                  style={{ backgroundColor: "#d9d9d9" }}
                  value={cost}
                  disabled
                  id="price"
                  name="price"
                />
              </div>
              <div className="mt-4">
                <div className="d-flex justify-content-center align-item-center">
                  {/* <button className="add-to-cart-btn mt-4">ADD TO CART</button> */}
                  <div className="col-md-12">
                    <Tooltip id="my-tooltip" style={{ width: "400px" }} />
                    <button
                      className="buy-btn"
                      disabled={buyBtn || error || qty === 0}
                      onClick={() => handlePurchase()}
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={
                        !availableQty ? "No Token Available For Purchase" : ""
                      }
                      data-tooltip-place="bottom">
                      BUY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="order-row px-5 pb-3">
          <Col className="order-column" xs={12} sm={12} md={12} lg={5}>
            <div className="place-order">
              <div className="d-flex justify-content-between align-items-center order-book">
                <h1 className="order-book-heading">ORDER BOOK</h1>
              </div>
              <Table striped>
                <thead className="text-center">
                  <tr>
                    <th className="order-th">VOLUME</th>
                    <th className="order-th">BUY PRICE</th>
                  </tr>
                </thead>
                <tbody>
                  {orderBook &&
                    orderBook?.map((info: any, index: number) => {
                      return (
                        <tr key={"data" + index} className="text-center">
                          <td>{info?.volume}</td>
                          <td>${info?.price}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {(!orderBook || !orderBook.length) && (
                <div className="text-center text-white col-12 mt-4">
                  No Data
                </div>
              )}
            </div>
          </Col>
          <Col className="order-column" xs={12} sm={12} md={12} lg={7}>
            <div className="place-order trade-history">
              <div className="d-flex justify-content-between align-items-center order-book">
                <h1 className="order-book-heading">TRADE HISTORY</h1>
              </div>

              <Table striped className="table">
                <thead className="text-center">
                  <tr>
                    <th className="order-th">PRICE</th>
                    <th className="order-th">VOLUME</th>
                    <th className="order-th">TIME</th>
                  </tr>
                </thead>
                <tbody>
                  {tradeHistory &&
                    tradeHistory?.map((info: any, index: any) => {
                      if (index < 10) {
                        return (
                          <tr key={"history" + index} className="text-center">
                            <td
                              className={
                                info.trend === "up"
                                  ? "table-green"
                                  : "table-red"
                              }>
                              {info.trend === "up" ? (
                                <span className="text-success text-center col-md-5">
                                  <img
                                    alt="upArrow"
                                    className="pe-1"
                                    src={ArrowUp}
                                  />
                                  <span className="px-1">$</span>
                                  {info?.price}
                                </span>
                              ) : info.trend === "down" ? (
                                <span className="text-danger">
                                  <img
                                    alt="downArrow"
                                    className="pe-1"
                                    src={ArrowDown}
                                  />
                                  <span className="px-1">$</span>
                                  {info?.price}
                                </span>
                              ) : (
                                <span>
                                  <span className="px-1">$</span>
                                  {info?.price}
                                </span>
                              )}
                            </td>
                            <td>{info?.volume}</td>
                            <td>{info?.date}</td>
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </Table>
              {(!tradeHistory || !tradeHistory.length) && (
                <div className="text-center text-white col-12 mt-4">
                  No Data
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <AddWalletModelComponent
        walletModal={walletModal}
        setWalletModal={setWalletModal}
      />
    </Fragment>
  );
};

export default SecondarySaleBody;
