import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Styles from "./ConfirmModelComponent.module.css";
import ButtonStyle from "../../Common/Button.module.css";

import { _userSign } from "../../../service/metamask_service";
import notify from "../../../utils/notify";
import { ConfirmFeesModal } from "../../../utils/interfaces/inputInterface";
import metamask from "../../../utils/metamask";
import {
  relayerBulkTransferTransaction,
  relayerSubsidizeTransaction,
  updateDirectTransactionHashAPI,
} from "../../../service/api";
import { setLoader } from "../../../redux/actions/layout-action";
import { getShortName } from "../../../utils/common";

interface Props {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  setShowLoader: (value: boolean) => void;
  data: ConfirmFeesModal;
  metaData: any;
  handlePayment: any;
}

const ConfirmModelComponent = ({
  showModal,
  setShowModal,
  setShowLoader,
  data,
  metaData,
  handlePayment,
}: Props) => {
  return (
    <Modal centered show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <div className={Styles.header}>Fees Confirm</div>
      </Modal.Header>
      <Modal.Body className="p-5">
        <div className={Styles.subHeader}>
          Your are sending {metaData.amount} Tokens to{" "}
          {getShortName(metaData.address, false)} wallet address. An additional
          fees of {data.fee}GDI Tokens will be charged to you and will be
          deducted from your wallet.
        </div>
        <div className={Styles.btn}>
          <button
            onClick={() => setShowModal(false)}
            className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeWhiteBackground} me-3 px-2`}>
            Reject
          </button>
          <button
            onClick={() => {
              setShowModal(false);
              handlePayment(data, false);
            }}
            className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} me-3 px-2`}>
            Confirm
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModelComponent;
