/* eslint-disable react-hooks/exhaustive-deps */
import "./myOrder.css";
import { Fragment, useEffect, useState } from "react";
import { fetchMintDetails, getOrderList } from "../../service/api";
import { useDispatch, useSelector } from "react-redux";
import { setOrderList } from "../../redux/actions";
import Pagination from "./Pagination";
import CurrencyFormat from "react-currency-format";
import GoldTokenSmall from "../../Assets//Images/goldefi/goldTokenSmall.svg";
import GoldIcon from "../../Assets//Images/goldefi/goldIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa";
import notify from "../../utils/notify";
import { GoldDecimal, formatMoney, getDateFormat } from "../../utils/common";
import ButtonStyle from "../../Components/Common/Button.module.css";
import { SignMessage, GetPublicKey } from "../../utils/AWS/aws_service";
import { GenosisSignTransaction } from "../../service/genosis_service";
import metamask from "../../utils/metamask";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TransactionModalComponent from "./TransactionModalComponent";
const MyOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [generalData, setGeneralData] = useState({
    token_value: 0,
    weight_of_gold: 0,
    token_available: 0,
    token_redeemed: 0,
    value_in_gold: 0,
  });
  const [orderSetting, setOrderSetting] = useState({
    sortBy: "",
    sortOrder: "desc",
  });

  const { orderList } = useSelector((state: any) => state.orderReducer);

  const getMyOrderList = async (
    e: any = 0,
    sortBy: string = "",
    sortOrder: string = ""
  ) => {
    try {
      const res: any = await getOrderList(
        e != 0 ? e : pageNo,
        sortBy,
        sortOrder
      );

      if (res.status) {
        dispatch(setOrderList(res.data_obj));
        setGeneralData(res.order_list[0]);
        setDataLoaded(true);
        setTotalPages(res.total_pages);
      }
    } catch (error) {
      console.log("eerr", error);
    }
  };
  const getMintDetail = async (e: number = 0) => {
    try {
      const body = {
        order_id: e,
      };
      const res: any = await fetchMintDetails(body);
      if (res.status) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      console.log("getMintDetail", error);
    }
  };

  useEffect(() => {
    getMyOrderList();
  }, []);

  const handleMint = async (e: number = 0) => {
    try {
      const resp = await getMintDetail(e);
      if (resp.status) {
        delete resp.status;

        // const hash = await GenosisSignTransaction();
        const sigMessage = await SignMessage(resp);
        const publicKey = await GetPublicKey();
      }
    } catch (error) {
      console.log("handleMint", error);
    }
  };
  const handleOrderSetting = (name: string = "") => {
    setOrderSetting({
      sortBy: name,
      sortOrder: orderSetting.sortOrder === "asc" ? "desc" : "asc",
    });
    getMyOrderList(
      pageNo,
      name,
      orderSetting.sortOrder === "asc" ? "desc" : "asc"
    );
  };
  const DesktopOrderTable = () => {
    return (
      <div className="col-md-12 mt-3">
        <div className="row">
          <div className="col-md-12 mx-3 px-3 mb-3">
            <div className="d-flex justify-content-between">
              <div
                className="tableHeading col px-1 fs-6"
                onClick={() => {
                  handleOrderSetting("name");
                }}>
                Order Date/Reference
              </div>
              <div
                className="tableHeading col px-1 fs-6"
                onClick={() => {
                  handleOrderSetting("product_uom_qty");
                }}>
                Token Quantity
              </div>
              <div
                className="tableHeading col px-1 fs-6"
                onClick={() => {
                  handleOrderSetting("product_uom_qty");
                }}>
                Gold Weight (t oz)
              </div>
              <div
                className="tableHeading col px-1 fs-6"
                onClick={() => {
                  handleOrderSetting("amount_total");
                }}>
                Total Value
              </div>
              <div className="tableHeading col px-1 fs-6">Platform Fees</div>
              <div className="tableHeading col px-1 fs-6">Order Status</div>
              <div className="tableHeading col px-1 fs-6">Payment Method</div>
              <div className="tableHeading col px-1 fs-6">Action</div>
              {/* <div className="tableHeading col px-1 fs-6">Action</div> */}
            </div>
          </div>
          {orderList && orderList.length > 0 ? (
            orderList.map((d: any, idx: number) => {
              return (
                <div
                  key={`order-+${idx}`}
                  className={
                    idx % 2
                      ? "col-md-12 mx-3 tableRowDark py-4 px-3 mt-1"
                      : "col-md-12 mx-3 tableRowLight py-4 px-3 mt-1"
                  }>
                  <div className="d-flex justify-content-between">
                    <div className=" tableText col px-1 fs-6">
                      <div className="tableHeading">
                        {getDateFormat(d.date_order)}
                      </div>
                      <div> / {d.order_name}</div>
                    </div>
                    <div className="tableText col px-1 fs-6">{d.qty}</div>
                    <div className="tableText col px-1 fs-6">
                      {d.gold_weight
                        ? d.gold_weight.toFixed(GoldDecimal)
                        : "N/A"}
                    </div>
                    <div className="tableText col px-1 fs-6">{d.amount}</div>
                    <div className="tableText col px-1 fs-6">
                      {d.platform_fee}
                    </div>
                    <div className="tableText col px-1 fs-6">
                      {GetOrderStatus(d.state)}
                    </div>
                    <div className="tableText col px-1 fs-6">
                      {d.payment_mode}
                    </div>
                    <div className="tableText col px-1 fs-6">
                      {d && d?.explorer_data ? (
                        <button
                          className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeSquareGoldBorder}`}
                          onClick={() => {
                            const body: any = [
                              {
                                ...d?.explorer_data[0],
                                order_name: d.order_name,
                              },
                            ];
                            setModalData(body);
                            setShowModal(true);
                          }}>
                          Details
                        </button>
                      ) : (
                        "N/A"
                      )}

                      {/* {d.blockchain_url ? (
                        <>
                          {d.blockchain_url.substring(0, 12) + "... "}
                          <FaRegCopy
                            fontSize={14}
                            onClick={() => {
                              copyTrancation(d.blockchain_url);
                            }}
                            className="ms-1"
                          />
                        </>
                      ) : (
                        "N/A"
                      )} */}
                    </div>
                    {/* <div className="tableText col px-1 fs-6">
                      <button
                        className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeSquareGoldBorder}`}
                        onClick={() => handleMint(d.order_id)}>
                        Mint
                      </button>
                    </div> */}
                  </div>
                </div>
              );
            })
          ) : (
            <div
              key="order-card-1"
              className="col-md-12 mx-3 tableRowDark py-4 px-3 mt-1">
              <h3 className="text-center text-white"> No Order Present</h3>
            </div>
          )}
        </div>
      </div>
    );
  };
  //order card mobile view
  const MobileOrderCard = () => {
    return (
      <Fragment>
        {orderList && orderList.length > 0 ? (
          orderList.map((d: any, idx: number) => {
            return (
              <div
                key={`order-card-+${idx}`}
                className={
                  idx % 2
                    ? "row orderCardDark py-3 px-2 mb-4"
                    : "row orderCardLight py-3 px-2 mb-4"
                }>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start">
                    <div className="col-md-12">Order Date/</div>
                    <div className="col-md-12">Reference</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">
                      {" "}
                      {getDateFormat(d.date_order)}
                    </div>
                    <div className="col-md-12">{d.order_name}</div>
                  </div>
                </div>
                <hr className="px-auto mt-1 text-white" />
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Token Quantity</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">{d.qty}</div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Gold Weight (t oz)</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">
                      {d.gold_weight
                        ? d.gold_weight.toFixed(GoldDecimal)
                        : "N/A"}
                    </div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Total Value</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">{d.amount}</div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Platform Fees</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">{d.platform_fee}</div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Order Status</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">{GetOrderStatus(d.state)}</div>
                  </div>
                </div>
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Payment Method</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">{d.payment_mode}</div>
                  </div>
                </div>
                {/* <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Action</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">
                      <button
                        className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeSquareGoldBorder}`}>
                        Mint
                      </button>
                    </div>
                  </div>
                </div> */}
                <div className="row mt-2 py-2">
                  <div className="col-md-5 col-5 tableHeading text-start ">
                    <div className="col-md-12">Action</div>
                  </div>
                  <div className="col-md-7 col-7 tableText text-start">
                    <div className="col-md-12">
                      <button
                        className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeSquareGoldBorder}`}
                        onClick={() => setShowModal(true)}>
                        Transaction Detail
                      </button>
                      {/* {d.blockchain_url ? (
                        <>
                          {d.blockchain_url.substring(0, 12) + "... "}
                          <FaRegCopy
                            fontSize={14}
                            onClick={() => {
                              copyTrancation(d.blockchain_url);
                            }}
                            className="ms-1"
                          />
                        </>
                      ) : (
                        "N/A"
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div key="order-card-1" className="row orderCardDark py-3 px-2 mb-4">
            <h3 className="text-center text-white"> No Order Present</h3>
          </div>
        )}
      </Fragment>
    );
  };
  // copy text to clipboard
  const copyTrancation = (data: string) => {
    navigator.clipboard.writeText(data);
    notify.success("Transaction link copied");
  };

  const GetOrderStatus = (status: string) => {
    if (status === "placed") {
      return (
        <span className="px-3 py-2 badge bg-success  rounded text-uppercase">
          {status}
        </span>
      );
    }
    if (status === "draft") {
      return (
        <span
          className="px-3 py-2 badge rounded text-uppercase"
          style={{ backgroundColor: "#8e810c" }}>
          {status}
        </span>
      );
    }
    return (
      <span
        className="px-3 py-2 badge rounded text-uppercase"
        style={{ background: "#902c0a" }}>
        {status}
      </span>
    );
  };
  return (
    <>
      <div className="orderContainer" id="order-body">
        <div className="p-2 p-md-5 mx-4">
          {/* my token section */}
          <div className="row mt-4">
            <div className="col-12 col-md-9 d-flex mobileHead">
              <div className="">
                <h1 className="text-white"> My Tokens</h1>
              </div>
              <div className=" ms-4 justify-conetnt-center">
                <button
                  type="button"
                  className=" buyBtn px-4 py-3"
                  onClick={() => navigate("/buy-tokens")}>
                  Buy Tokens
                </button>
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-end  align-item-center">
              <label
                className="datePickerHeading  text-white fs-5 d-none"
                htmlFor="dateInput">
                Select Date Range
              </label>
              <input
                type="date"
                id="dateInput"
                className="dateInput px-3 py-2 d-none"
              />
            </div>
          </div>
          <div className="row mt-5">
            {/* my tokens  */}
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <div className="row tokenContainer px-3 py-2 ">
                <div className="col-6 col-md-4 tokenBorderEnd px-md-4 py-2">
                  <div className="tokenDivHeading col-md-12 fs-6 ">
                    Token Available
                  </div>
                  <div className="col-md-12 mt-1 d-flex justify-content-left align-items-center  ">
                    <img
                      src={GoldTokenSmall}
                      alt="gold"
                      className="goldTokenImg "
                    />
                    <ReactTooltip
                      id="my-tooltip-1"
                      style={{
                        backgroundColor: "#00112b",
                        color: "#fff",
                        fontFamily: "Poppins",
                      }}
                    />
                    <div
                      data-tooltip-id="my-tooltip-1"
                      data-tooltip-place="top-start"
                      data-tooltip-content={generalData.token_available.toString()}
                      className="text-start fs-4 ps-2 tokenDivText   text-break">
                      {formatMoney(generalData.token_available)}
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 tokenBorderEnd px-md-4 py-2">
                  <div className="tokenDivHeading col-md-12 fs-6 ">
                    Weight Of Gold
                  </div>
                  <div className="col-md-12 d-flex mt-1 text-start jusitify-content-center">
                    <img
                      src={GoldIcon}
                      height={30}
                      alt="gold"
                      className="goldTokenImg"
                    />
                    <div className="col-md-12 mt-1 d-flex justify-content-left align-items-center  ">
                      <ReactTooltip
                        id="my-tooltip-2"
                        style={{
                          backgroundColor: "#00112b",
                          color: "#fff",
                          fontFamily: "Poppins",
                        }}
                      />
                      <div
                        data-tooltip-id="my-tooltip-2"
                        data-tooltip-place="top-start"
                        data-tooltip-content={generalData.weight_of_gold.toString()}
                        className="text-start fs-4 ps-2 tokenDivText   text-break">
                        {formatMoney(generalData.weight_of_gold, "")} t oz
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4  px-md-4 py-2">
                  <div className="tokenDivHeading col-md-12 fs-6 ">
                    Token Value
                  </div>
                  <div className="col-md-12 mt-1 text-start">
                    <ReactTooltip
                      id="my-tooltip-3"
                      style={{
                        backgroundColor: "#00112b",
                        color: "#fff",
                        opacity: 0,
                        fontFamily: "Poppins",
                      }}
                    />

                    <div
                      data-tooltip-id="my-tooltip-3"
                      data-tooltip-place="top-start"
                      data-tooltip-content={generalData.token_value.toString()}
                      className="text-start fs-4 ps-2 tokenDivText   text-break">
                      $ {formatMoney(generalData.token_value)}
                    </div>
                    {/* <CurrencyFormat
                      value={}
                      displayType={"text"}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={"$ "}
                      className="text-start fs-4 pe-2 tokenDivText   text-break"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* gap */}
            <div className="col-md-2 col-sm-12"></div>
            {/*token available  */}
            <div className="col-lg-4 col-md-12 col-sm-12 mt-sm-3">
              <div className="row">
                <div className="col-md-2 col-sm-12"></div>
                <div className="col-6 col-md-4 tokenBorderEnd pe-md-1 py-2">
                  <div className="tokenDivHeading col-md-12 fs-6">
                    Tokens Redeemed
                  </div>
                  <div className="d-flex align-items-center mt-1 ">
                    <div>
                      <img
                        src={GoldTokenSmall}
                        alt="gold"
                        className="goldTokenImg"
                      />
                    </div>
                    <div>
                      <CurrencyFormat
                        value={generalData.token_redeemed}
                        displayType={"text"}
                        decimalScale={2}
                        thousandSeparator={true}
                        prefix={""}
                        className="tokenDivText ms-1 px-1 fs-4 text-break "
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-6 ps-3 pe-md-1 py-2">
                  <div className="tokenDivHeading col-md-12 fs-6 ">
                    Value In Gold
                  </div>
                  <div className="col-md-12 d-flex mt-1 text-start jusitify-content-center">
                    <img
                      src={GoldIcon}
                      height={30}
                      alt="gold"
                      className="goldTokenImg"
                    />
                    <div>
                      <span className="tokenDivText fs-7 align-bottom text-break">
                        <span className=" px-2 fs-4">
                          <CurrencyFormat
                            value={generalData.value_in_gold}
                            displayType={"text"}
                            decimalScale={GoldDecimal}
                            thousandSeparator={true}
                            prefix={""}
                            className="text-start  tokenDivText   text-break"
                          />
                        </span>
                        t oz
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-end">
              <span>
                <Link to="/redeem-list" className=" px-2 mt-3 redeemText fs-6">
                  View All Redeemption
                </Link>{" "}
              </span>
            </div>
          </div>
          {/* ordeer section */}
          <div className="col-md-12 mt-3 mb-3 mb-md-0">
            <div className="row">
              <div>
                <h2 className="text-bold text-white text-start">
                  Orders Placed
                </h2>
              </div>
            </div>
          </div>
          {/* desktop view */}
          <div className="d-none d-md-block d-xl-block mb-4">
            <DesktopOrderTable />
          </div>
          {/* desktop view */}
          <div className="d-block d-xl-none	 d-md-none mb-4">
            <MobileOrderCard />
          </div>
          <div className="d-flex justify-content-center">
            {orderList && orderList.length > 0 ? (
              <Pagination
                callAPI={getMyOrderList}
                totalPages={totalPages}
                setPageNo={setPageNo}
                pageNo={pageNo}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <TransactionModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        data={modalData}
      />
    </>
  );
};
export default MyOrder;
