import React from "react";
import CurrencyFormat from "react-currency-format";
import Styles from "./money.module.css";
import { useSelector } from "react-redux";
import TokenCoin from "../../../Assets/Images/goldefi/token-coin.png";
import { formatMoney } from "../../../utils/common";
import { Tooltip as ReactTooltip } from "react-tooltip";

const MoneyComponent = () => {
  const { total_money_invested } = useSelector(
    (state: any) => state.dashboardReducer
  );

  return (
    <div className={"row px-3 py-4 " + Styles.tokenContainer}>
      <div className="col-md-7 col-sm-12 text-start">
        <div className="col-12">
          <div>
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect
                x="0.410156"
                y="0.807617"
                width="34.8711"
                height="34.8711"
                rx="17.4355"
                fill="#C58C1C"
              />
              <g clip-path="url(#clip0_396_2809)">
                <path
                  d="M17.8457 6.24316C11.2257 6.24316 5.8457 11.6232 5.8457 18.2432C5.8457 24.8632 11.2257 30.2432 17.8457 30.2432C24.4657 30.2432 29.8457 24.8632 29.8457 18.2432C29.8457 11.6232 24.4657 6.24316 17.8457 6.24316ZM28.0257 18.2432C28.0257 23.8532 23.4657 28.4232 17.8457 28.4232C12.2257 28.4232 7.6757 23.8532 7.6757 18.2432C7.6757 12.6332 12.2357 8.07316 17.8457 8.07316C23.4557 8.07316 28.0257 12.6332 28.0257 18.2432Z"
                  fill="white"
                />
                <path
                  d="M17.8457 15.0032C18.2357 15.0032 18.6057 15.0832 18.8757 15.2332C19.0757 15.3432 19.3057 15.5432 19.3057 15.8632C19.3057 16.1132 19.4057 16.3432 19.5757 16.5132C19.9257 16.8632 20.5257 16.8632 20.8757 16.5132C21.0457 16.3432 21.1457 16.1132 21.1457 15.8632C21.1457 14.3332 19.9357 13.5232 18.7757 13.2732V12.7032C18.7757 12.4532 18.6757 12.2132 18.5057 12.0432C18.3357 11.8732 18.0957 11.7732 17.8457 11.7732C17.3457 11.7732 16.9357 12.1932 16.9457 12.6932V13.2532C15.7757 13.5032 14.5757 14.3032 14.5757 15.8432C14.5757 16.7832 14.9857 17.6632 15.6657 18.1932C16.2457 18.6432 16.8957 18.8532 17.4557 19.0432L17.5657 19.0832C18.0957 19.2632 18.6357 19.4532 18.9257 19.6732C19.1857 19.8732 19.3057 20.0332 19.3057 20.5832C19.3057 21.7232 16.3957 21.7232 16.3957 20.5832C16.3957 20.3432 16.3057 20.1032 16.1357 19.9232C15.7957 19.5732 15.1757 19.5732 14.8257 19.9232C14.6557 20.0932 14.5557 20.3432 14.5657 20.5832C14.5657 22.1232 15.7757 22.9232 16.9357 23.1732V23.7332C16.9357 23.9732 17.0257 24.2232 17.1957 24.3932C17.3657 24.5632 17.6057 24.6632 17.8457 24.6632C18.0857 24.6632 18.3257 24.5632 18.5057 24.3832C18.6757 24.2132 18.7757 23.9632 18.7657 23.7232V23.1632C19.9357 22.9132 21.1357 22.1132 21.1357 20.5732C21.1357 19.6332 20.7257 18.7532 20.0457 18.2232C19.4657 17.7732 18.8157 17.5532 18.2357 17.3632L18.1357 17.3332C17.6057 17.1532 17.0657 16.9632 16.7757 16.7432C16.5157 16.5432 16.3957 16.3832 16.3957 15.8332C16.3957 15.2832 17.1257 14.9632 17.8557 14.9632L17.8457 15.0032Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_396_2809">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(5.8457 6.24316)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div className="col-12 mt-2">
          <h6 className=" text-bolder px-2">Total money invested</h6>
        </div>
        <div className="col-12 text-start">
          <ReactTooltip id="my-tooltip-1" />

          <div
            data-tooltip-id="my-tooltip-1"
            data-tooltip-place="top-start"
            data-tooltip-content={total_money_invested}
            className={`${Styles.tokenHeading}  px-2`}>
            $ {formatMoney(total_money_invested)}
          </div>
        </div>
      </div>

      <div className="d-none d-mb-block d-xl-block col-md-5">
        <img
          src={TokenCoin}
          className={Styles.cardImg + " pt-4"}
          alt="token-img"
        />
      </div>
    </div>
  );
};
export default MoneyComponent;
